/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { Feature, Polygon, LineString } from '@turf/turf';
import { MarkerDragEvent } from 'react-map-gl';
import {
  pointInCountry,
  pointIsInSpecificCountry,
  polygonInsideCountry,
  isLineStringOverlaping,
  isPolygonOverlaping,
  pointInPolygons,
  isLineOverlappingPolygons,
  polygonsOverlaps,
  calculateAreaOfPolygon,
  findFieldToEdit,
} from '../utils/helpers/geospatial';
import polylabel from 'polylabel';
import { Field, detectFieldFeature } from 'context/store/recommendationFormReducer';
import { ModeTypes, OverlappingGeometryTypes } from 'context/store/flowReducer';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useFlowActions } from 'context/actions/flowActions';
import { useRecommendationFormActions } from 'context/actions/recommendationFormActions';
import { useAppState } from 'context/AppState';
import { DetectFieldResponse } from 'base/types/detectField';

const getCoordsFromEvent = (
  e: (mapboxgl.MapMouseEvent & mapboxgl.EventData) | MarkerDragEvent
): [number, number] => {
  const { lat, lng } = e.lngLat.wrap();
  return [lng, lat];
};

const getMiddlePointBetweenCoords = ({
  firstCoords,
  secondCoords,
}: {
  firstCoords: [number, number];
  secondCoords: [number, number];
}): [number, number] => [
  (firstCoords[0] + secondCoords[0]) / 2,
  (firstCoords[1] + secondCoords[1]) / 2,
];

const EMPTY_LINESTRING: Feature<LineString> = {
  type: 'Feature',
  properties: {},
  geometry: {
    type: 'LineString',
    coordinates: [],
  },
};

const EMPTY_POLYGON: Feature<Polygon> = {
  type: 'Feature',
  properties: {},
  geometry: {
    type: 'Polygon',
    coordinates: [],
  },
};

type TCLickedCoord = {
  coords: [number, number];
  source: string;
};

interface IProps {
  polygonToEdit: Feature<Polygon> | undefined;
  clickedCoordEvent?: (mapboxgl.MapMouseEvent & mapboxgl.EventData) | MarkerDragEvent;
  cursorCoordEvent?: (mapboxgl.MapMouseEvent & mapboxgl.EventData) | MarkerDragEvent;
  handleUndoClick?: boolean;
  handleConfirmClick?: boolean;
  isDrawing: boolean;
  setIsDrawing: ({ isDrawing }: { isDrawing: boolean }) => void;
  setShowDrawingWarning: ({ show }: { show: boolean }) => void;
  setShowInvalidBoundaryModal: ({ show }: { show: boolean }) => void;
  handleDeleteClick?: boolean;
  handleEditClick?: boolean;
  confirmedPolygons?: Field[];
  doReset?: boolean;
  setCurrentFieldArea: (payload: { currentFieldArea: string }) => void;
  setPreviousFieldArea: (payload: { previousFieldArea: string }) => void;
  currentModeType?: ModeTypes;
  fieldsHasChange?: boolean;
  countryCode?: string;
  fields: Field[];
  isMobile?: boolean;
  setTempFieldMapData: (payload: { data: detectFieldFeature | undefined;}) => void;
  setFieldsMapData: (payload: {data: DetectFieldResponse}) => void;
  fieldsMapData: DetectFieldResponse;
}

const useDrawableMap = ({
  polygonToEdit,
  clickedCoordEvent,
  cursorCoordEvent,
  handleUndoClick,
  isDrawing,
  setIsDrawing,
  setShowDrawingWarning,
  setShowInvalidBoundaryModal,
  handleDeleteClick,
  confirmedPolygons,
  doReset,
  setCurrentFieldArea,
  setPreviousFieldArea,
  currentModeType,
  fieldsHasChange,
  countryCode,
  fields,
  isMobile,
  setTempFieldMapData,
  setFieldsMapData,
  fieldsMapData,
}: IProps): {
  currentPolygonVertexCoords: Array<[number, number]>;
  boundaryLineCoords: Feature<LineString>;
  geoJsonMoveCoords: Feature<LineString>;
  currentPolygon: Feature<Polygon> | undefined;
  handleOnDragMarker: (currentCoords: [number, number], index: number) => void;
  handleMarkerClick: (index: number) => void;
  handleOnDragEndMarker: (index: number) => void;
  displayNotification: boolean;
  snapShots: Array<Array<[number, number]>>;
  geoJsonLastTwoCoords: Feature<LineString>;
  tempPolygon: Feature<Polygon> | undefined;
} => {
  const {
    setTempBoundaryField,
    setFieldIdToEdit,
    setFieldEditId,
    setTempFields,
    setEnableFieldSaveButton,
    setIsDeletingTempField,
    saveFields,
    setPreviousFieldAreaMobile,
  } = useRecommendationFormActions();

  const {
    recommendationForm: { isDeletingTempField, currentFieldArea },
  } = useAppState();

  const [currentPolygonVertexCoords, setCurrentPolygonVertexCoords] = useState<
    Array<[number, number]>
  >([]);
  const [boundaryLineCoords, setBoundaryLineCoords] =
    useState<Feature<LineString>>(EMPTY_LINESTRING);
  const [geoJsonMoveCoords, setGeoJsonMoveCoords] = useState<Feature<LineString>>(EMPTY_LINESTRING);
  const [currentPolygon, setCurrentPolygon] = useState<Feature<Polygon>>();
  const [latestClickedCoords, setLatestClickedCoords] = useState<[number, number]>();
  const [boundariesConfirmedOnce, setBoundariesConfirmedOnce] = useState(false);
  const [snapShots, setSnapShots] = useState<Array<Array<[number, number]>>>([]);
  const [isFieldEditing, setIsFieldEditing] = useState(false);
  const [geoJsonLastTwoCoords, setGeoJsonLastTwoCoords] =
    useState<Feature<LineString>>(EMPTY_LINESTRING);
  const [tempPolygon, setTempPolygon] = useState<Feature<Polygon>>();
  const { setOverlappingGeometryType, setIsMapBounded } = useFlowActions();

  const [displayNotification, setDisplayNotification] = useState(false);
  const flags = useFlags();
  const isBrazilEnable = flags.isBrazilEnable;

  const arePointsOnStraightLine = (coordinateArray: [number, number][]): boolean => {
    if (coordinateArray.length < 3) {
      return true;
    }
    const [x1, y1] = coordinateArray[0];
    const [x2, y2] = coordinateArray[1];
    const initialSlope = Number(((y2 - y1) / (x2 - x1)).toFixed(8));
    return coordinateArray.every(([x, y], index) => {
      if (index < 2) {
        return true;
      }
      const currentSlope = Number(((y - y2) / (x - x2)).toFixed(8));
      return currentSlope === initialSlope;
    });
  };

  const handleFirstAndLastCoords = (
    coordArray: [number, number][],
    latestPolygonVertexCoords: [number, number][] = currentPolygonVertexCoords
  ) => {
    const isNewLineOverlappingAnyPolygon =
      coordArray.length &&
      confirmedPolygons?.length &&
      isLineOverlappingPolygons({
        line: [coordArray[0], coordArray[coordArray.length - 1]],
        polygonArray: confirmedPolygons,
      });
    if (
      !isNewLineOverlappingAnyPolygon &&
      !arePointsOnStraightLine(coordArray) &&
      !isLineStringOverlaping({
        newLine: [coordArray[coordArray.length - 1], coordArray[0]],
        oldLine: latestPolygonVertexCoords,
      })
    ) {
      const geoJsonLastAndFirstCopy: Feature<LineString> = {
        ...boundaryLineCoords,
        geometry: {
          type: 'LineString',
          coordinates: [coordArray[0], coordArray[coordArray.length - 1]],
        },
      };
      const newPolygon: Feature<Polygon> = {
        ...EMPTY_POLYGON,
        geometry: { ...EMPTY_POLYGON.geometry, coordinates: [coordArray] },
        properties: {
          center: polylabel([coordArray]),
          id: Math.floor(Date.now() / 1000),
        },
      };
      setTempPolygon(newPolygon);
      setGeoJsonLastTwoCoords(geoJsonLastAndFirstCopy);
      setOverlappingGeometryType({ type: OverlappingGeometryTypes.NONE });
      setEnableFieldSaveButton({ enableFieldSaveButton: true });
    } else {
      setTempPolygon(undefined);
      setGeoJsonLastTwoCoords(EMPTY_LINESTRING);
      setOverlappingGeometryType({
        type: coordArray.length > 4 ? OverlappingGeometryTypes.LINE : OverlappingGeometryTypes.NONE,
      });
      setEnableFieldSaveButton({ enableFieldSaveButton: false });
    }
  };

  const resetValueFunc = () => {
    setCurrentPolygon(undefined);
    setCurrentPolygonVertexCoords([]);
    setBoundaryLineCoords(EMPTY_LINESTRING);
    setLatestClickedCoords(undefined);
    setSnapShots([]);
    setGeoJsonMoveCoords({
      ...EMPTY_LINESTRING,
      geometry: { ...EMPTY_LINESTRING.geometry, coordinates: [] },
    });
    setGeoJsonLastTwoCoords(EMPTY_LINESTRING);
    setTempPolygon(undefined);
    setBoundariesConfirmedOnce(false);
    setEnableFieldSaveButton({ enableFieldSaveButton: false });
    setIsDrawing({ isDrawing: false });
  };
  const resetValues = () => {
    resetValueFunc();
  };

  useEffect(() => {
    if (isFieldEditing && currentModeType === ModeTypes.EDITING && polygonToEdit) {
      const stateCoords = polygonToEdit.geometry.coordinates[0] as [number, number][];
      setCurrentPolygonVertexCoords(stateCoords);
      setSnapShots([stateCoords]);
      setCurrentPolygon(polygonToEdit);
      setBoundariesConfirmedOnce(true);
      setGeoJsonLastTwoCoords(EMPTY_LINESTRING);
      setTempPolygon(undefined);
    }
  }, [isFieldEditing]);

  useEffect(() => {
    resetValues();
  }, [doReset]);

  useEffect(() => {
    if (!currentPolygon) {
      setCurrentFieldArea({ currentFieldArea: '' });
    } else {
      const polygonArea = calculateAreaOfPolygon(
        currentPolygon?.geometry.coordinates[0] as [number, number][]
      );
      setPreviousFieldArea({previousFieldArea: currentFieldArea});
      setCurrentFieldArea({ currentFieldArea: parseFloat(polygonArea).toFixed(2).toString() });
    }
  }, [currentPolygon]);

  useEffect(() => {
    if (currentModeType !== ModeTypes.EDITING) {
      setIsFieldEditing(false);
    }
  }, [currentModeType]);

  useEffect(() => {
    if (currentModeType === ModeTypes.NO_ACTION) {
      setGeoJsonLastTwoCoords(EMPTY_LINESTRING);
      setTempPolygon(undefined);
    }
    if (polygonToEdit && currentModeType === ModeTypes.EDITING) {
      const stateCoords = polygonToEdit.geometry.coordinates[0] as [number, number][];
      setCurrentPolygonVertexCoords(stateCoords);
      setSnapShots([stateCoords]);
      setCurrentPolygon(polygonToEdit);
      setBoundariesConfirmedOnce(true);
      setGeoJsonLastTwoCoords(EMPTY_LINESTRING);
      setTempPolygon(undefined);
    }
  }, [currentModeType, fieldsHasChange]);

  // Effect: Update lineString object for current boundary when there is a change on Polygon vertex coords
  useEffect(() => {
    const newBoundaryLineCoords: Feature<LineString> = {
      ...boundaryLineCoords,
      geometry: { type: 'LineString', coordinates: currentPolygonVertexCoords },
    };
    setBoundaryLineCoords(newBoundaryLineCoords);
  }, [currentPolygonVertexCoords]);

  /* Effect:
    - Update the initial point for the moving line when moving around the map when there is a new vertex
  */
  useEffect(() => {
    if (!latestClickedCoords) {
      const geoJsonMoveCoordsCopy: Feature<LineString> = {
        ...boundaryLineCoords,
        geometry: { type: 'LineString', coordinates: [] },
      };
      setGeoJsonMoveCoords(geoJsonMoveCoordsCopy);
    }
  }, [latestClickedCoords]);

  /* Effect:
    - Add new coords when there is a click and polygon is not completed (It also adds the "+" vertex)
    - Add one snapshot so it can be undo later
    - Updates current Polygon vertex coords
  */

  const handleSetIsDrawData = () => {
    if (!isDrawing && currentModeType !== ModeTypes.EDITING) {
      setIsDrawing({ isDrawing: true });
    }
  };

  const checkEditFunction = (clickedCoord: [number, number]) => {
    if (confirmedPolygons?.length && currentModeType === ModeTypes.EDITING && isMobile) {
      const fieldIdToEdit = findFieldToEdit(
        { lng: clickedCoord[0], lat: clickedCoord[1] },
        confirmedPolygons
      );
      if (fieldIdToEdit && !fieldIdToEdit.isDetectedField) {
        const fieldId = fieldIdToEdit?.id;
        const mapFieldToEdit = fieldsMapData.features.find((item) => item.properties.id === fieldId);
        setTempFieldMapData({data: mapFieldToEdit});
        setFieldsMapData({data: {
          type: 'FeatureCollection',
          features: fieldsMapData.features.filter((item) => item.properties.id !== fieldId),
          isDigifarm: false,
        }});
        setTempBoundaryField({ boundary: fieldIdToEdit?.boundary });
        setFieldIdToEdit({ fieldId });
        setFieldEditId({ fieldId });
        setIsMapBounded({ bounded: false });
        setIsFieldEditing(true);
        setTempFields({ fields: confirmedPolygons });
        setPreviousFieldAreaMobile({previousFieldAreaMobile: fieldIdToEdit.hectares});
        const fieldsNotToEdit = confirmedPolygons.filter((item) => item.id !== fieldId);
        saveFields({ fields: fieldsNotToEdit });
      }
      return true;
    }
    return false;
  };

  useEffect(() => {
    setDisplayNotification(false);
    if (!clickedCoordEvent || currentPolygon) {
      return;
    }
    handleSetIsDrawData();
    const clickedCoord = getCoordsFromEvent(clickedCoordEvent);
    if (checkEditFunction(clickedCoord)) {
      return;
    }

    // Check node for draw a boundary
    if (!pointInCountry({ lng: clickedCoord[0], lat: clickedCoord[1] }, flags)) {
      setShowDrawingWarning({ show: true });
      return;
    }

    // Check if the current point belongs to the country that has a field
    if (
      countryCode &&
      fields.length &&
      !pointIsInSpecificCountry({ lng: clickedCoord[0], lat: clickedCoord[1] }, countryCode)
    ) {
      setShowInvalidBoundaryModal({ show: true });
      return;
    }

    if (
      confirmedPolygons?.length &&
      pointInPolygons({ lng: clickedCoord[0], lat: clickedCoord[1] }, confirmedPolygons)
    ) {
      return;
    }
    const { length } = currentPolygonVertexCoords;
    if (
      confirmedPolygons?.length &&
      length &&
      isLineOverlappingPolygons({
        line: [...currentPolygonVertexCoords, clickedCoord],
        polygonArray: confirmedPolygons,
      })
    ) {
      return;
    }

    if (
      length > 2 &&
      latestClickedCoords &&
      isLineStringOverlaping({
        newLine: [latestClickedCoords, clickedCoord],
        oldLine: currentPolygonVertexCoords,
      })
    ) {
      return;
    }
    const coordsCopy = [...currentPolygonVertexCoords];
    if (length && latestClickedCoords) {
      const latestCoord: [number, number] = latestClickedCoords;

      const middlePointCoords = getMiddlePointBetweenCoords({
        firstCoords: latestCoord,
        secondCoords: clickedCoord,
      });
      coordsCopy.push(middlePointCoords);
    }
    coordsCopy.push(clickedCoord);
    setLatestClickedCoords(clickedCoord);
    handleFirstAndLastCoords(coordsCopy);
    setCurrentPolygonVertexCoords(coordsCopy);
    setSnapShots((prevSnapShots) => [...prevSnapShots, coordsCopy]);
  }, [clickedCoordEvent]);

  /* Effect:
    - Tracks and updates the movement when in drawing mode
   */
  useEffect(() => {
    if (!isDrawing || !cursorCoordEvent || !latestClickedCoords) {
      return;
    }
    const cursorCoord = getCoordsFromEvent(cursorCoordEvent);
    if (!cursorCoord) {
      return;
    }
    const geoJsonMoveCoordsCopy: Feature<LineString> = {
      ...boundaryLineCoords,
      geometry: { type: 'LineString', coordinates: [latestClickedCoords, cursorCoord] },
    };
    setGeoJsonMoveCoords(geoJsonMoveCoordsCopy);
  }, [cursorCoordEvent]);

  /* Effect:
    - Handle Undo Click
    - Pop latest snapshot state and update the vertex, line and polygons
    - If boundary have been confirmed at least once, 1 snapshot should be keeped
  */
  useEffect(() => {
    if ((snapShots.length === 1 && boundariesConfirmedOnce) || snapShots.length === 0) {
      return;
    }

    const snapShotsCopy = [...snapShots];
    snapShotsCopy.pop();
    const snapShot = snapShotsCopy[snapShotsCopy.length - 1] || [];

    setCurrentPolygonVertexCoords(snapShot);
    const latestCoord = snapShot[snapShot.length - 1];
    if (snapShot.length > 2 && JSON.stringify(snapShot[0]) === JSON.stringify(latestCoord)) {
      const newPolygon: Feature<Polygon> = {
        ...EMPTY_POLYGON,
        geometry: { ...EMPTY_POLYGON.geometry, coordinates: [snapShot] },
        properties: {
          center: polylabel([snapShot]),
          id: Math.floor(Date.now() / 1000),
        },
      };
      setCurrentPolygon(newPolygon);
    } else {
      setCurrentFieldArea({ currentFieldArea: '' });
      setLatestClickedCoords(latestCoord);
      setCurrentPolygon(undefined);
      setIsDrawing({ isDrawing: true });
    }
    setSnapShots(snapShotsCopy);
    const geoJsonMoveCoordsCopy: Feature<LineString> = {
      ...boundaryLineCoords,
      geometry: { type: 'LineString', coordinates: [] },
    };
    // This is to show dotted line on undo the field which has more than 2 vertices.
    const trimmedCoordSnapshot = snapShot.slice(0, -2);
    handleFirstAndLastCoords(snapShot, trimmedCoordSnapshot);
    setGeoJsonMoveCoords(geoJsonMoveCoordsCopy);
    setDisplayNotification(!snapShot.length);
  }, [handleUndoClick]);

  const resetValuesWhenDeleted = () => {
    if (isDeletingTempField) {
      resetValueFunc();
      setIsDeletingTempField({ isDeletingTempField: false });
    }
  };

  useEffect(() => {
    if (isMobile) {
      resetValuesWhenDeleted();
    } else {
      resetValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleDeleteClick]);

  useEffect(() => {
    if (!latestClickedCoords) {
      const geoJsonMoveCoordsCopy: Feature<LineString> = {
        ...boundaryLineCoords,
        geometry: { type: 'LineString', coordinates: [] },
      };
      setGeoJsonMoveCoords(geoJsonMoveCoordsCopy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latestClickedCoords]);

  const handleOnDragMarker = (currentCoords: [number, number], index: number) => {
    const coordsCopy = [...currentPolygonVertexCoords];
    if (!currentPolygon) {
      setCurrentPolygonVertexCoords(coordsCopy);
      return;
    }

    coordsCopy[index] = [...currentCoords];
    if (currentPolygon && !index) {
      const { length } = currentPolygonVertexCoords;
      coordsCopy[length - 1] = [...currentCoords];
    }
    setCurrentPolygonVertexCoords(coordsCopy);
  };

  const handleMarkerClick = (index: number) => {
    const { length } = currentPolygonVertexCoords;
    if (!!index || length < 3) {
      return;
    }
    const copyCoords = [...currentPolygonVertexCoords];
    const middlePointCoords = getMiddlePointBetweenCoords({
      firstCoords: currentPolygonVertexCoords[length - 1],
      secondCoords: currentPolygonVertexCoords[0],
    });
    copyCoords.push(middlePointCoords);
    copyCoords.push(currentPolygonVertexCoords[0]);
    const newPolygon: Feature<Polygon> = {
      ...EMPTY_POLYGON,
      geometry: { ...EMPTY_POLYGON.geometry, coordinates: [copyCoords] },
      properties: {
        center: polylabel([copyCoords]),
        id: Math.floor(Date.now() / 1000),
      },
    };

    if (
      isPolygonOverlaping({
        polygonCoordinates: copyCoords,
      })
    ) {
      return;
    }

    if (!polygonInsideCountry(copyCoords, countryCode, isBrazilEnable)) {
      setShowDrawingWarning({ show: true });
      return;
    }

    if (confirmedPolygons?.length && polygonsOverlaps(copyCoords, confirmedPolygons)) {
      setOverlappingGeometryType({ type: OverlappingGeometryTypes.POLYGON });
      return;
    }
    setCurrentPolygon(newPolygon);
    setCurrentPolygonVertexCoords(copyCoords);
    setSnapShots((prevSnapShots) => [...prevSnapShots, copyCoords]);
    setIsDrawing({ isDrawing: false });
    setTempPolygon(undefined);
    setGeoJsonLastTwoCoords(EMPTY_LINESTRING);
  };

  const handleOnDragEndMarker = (index: number) => {
    const copyCoords = [...currentPolygonVertexCoords];
    // validate polygon is a completed polygon
    if (!currentPolygon) {
      setCurrentPolygonVertexCoords(copyCoords);
      return;
    }

    if (!pointInCountry({ lng: copyCoords[index][0], lat: copyCoords[index][1] }, flags)) {
      setShowDrawingWarning({ show: true });
      setCurrentPolygonVertexCoords(snapShots[snapShots.length - 1]);
      return;
    }

    if (
      confirmedPolygons?.length &&
      pointInPolygons({ lng: copyCoords[index][0], lat: copyCoords[index][1] }, confirmedPolygons)
    ) {
      setCurrentPolygonVertexCoords(snapShots[snapShots.length - 1]);
      return;
    }

    if (
      confirmedPolygons?.length &&
      polygonsOverlaps(currentPolygonVertexCoords, confirmedPolygons)
    ) {
      setCurrentPolygonVertexCoords(snapShots[snapShots.length - 1]);
      setOverlappingGeometryType({ type: OverlappingGeometryTypes.POLYGON });
      return;
    }

    const { length } = currentPolygonVertexCoords;
    let indexToInsert = index;
    const prevCoordIndex = index ? index - 1 : -1;
    const nextCoordIndex = index + 1 >= length ? -1 : index + 1;
    const isOdd = index % 2 !== 0;

    if (
      !confirmedPolygons?.length &&
      length > 2 &&
      isPolygonOverlaping({
        polygonCoordinates: currentPolygonVertexCoords,
      })
    ) {
      setCurrentPolygonVertexCoords(snapShots[snapShots.length - 1]);
      return;
    }

    if (prevCoordIndex > -1 && isOdd) {
      const newMiddleVertex = getMiddlePointBetweenCoords({
        firstCoords: currentPolygonVertexCoords[prevCoordIndex],
        secondCoords: currentPolygonVertexCoords[index],
      });
      copyCoords.splice(indexToInsert, 0, newMiddleVertex);
      indexToInsert++;
    }
    if (nextCoordIndex > -1 && isOdd) {
      const newMiddleVertex = getMiddlePointBetweenCoords({
        firstCoords: currentPolygonVertexCoords[nextCoordIndex],
        secondCoords: currentPolygonVertexCoords[index],
      });
      copyCoords.splice(indexToInsert + 1, 0, newMiddleVertex);
    }

    if (!polygonInsideCountry(copyCoords, countryCode, isBrazilEnable)) {
      setShowDrawingWarning({ show: true });
      setCurrentPolygonVertexCoords(snapShots[snapShots.length - 1]);
      return;
    }

    if (currentPolygon) {
      const newPolygon: Feature<Polygon> = {
        ...EMPTY_POLYGON,
        geometry: { ...EMPTY_POLYGON.geometry, coordinates: [copyCoords] },
        properties: {
          center: polylabel([copyCoords]),
          id: Math.floor(Date.now() / 1000),
        },
      };
      setCurrentPolygon(newPolygon);
    }
    setCurrentPolygonVertexCoords(copyCoords);
    setSnapShots((prevSnapShots) => [...prevSnapShots, copyCoords]);
  };

  return {
    currentPolygonVertexCoords,
    boundaryLineCoords,
    geoJsonMoveCoords,
    currentPolygon,
    handleOnDragMarker,
    handleMarkerClick,
    handleOnDragEndMarker,
    displayNotification,
    snapShots,
    geoJsonLastTwoCoords,
    tempPolygon,
  };
};

export default useDrawableMap;

export type { TCLickedCoord };
