import { StressLine } from './AbioticGraph.styles';

const AbioticGraph = ({
  left,
  weekWidth,
  stressValue,
  type,
  hasDottedLineAtStart,
  hasDottedLineAtEnd,
  isVisible,
}: {
  left: number;
  weekWidth: number;
  stressValue: number;
  type: string;
  hasDottedLineAtStart: boolean;
  hasDottedLineAtEnd: boolean;
  isVisible: boolean;
}) => {
  return (
    <StressLine
      left={left}
      width={weekWidth}
      stressValue={stressValue}
      type={type}
      hasDottedLineAtStart={hasDottedLineAtStart}
      hasDottedLineAtEnd={hasDottedLineAtEnd}
      isVisible={isVisible}
    />
  );
};

export default AbioticGraph;
