import styled from 'styled-components';

interface WebProps {
  isSelected?: boolean;
  isMobile: boolean;
}

export const MainDiv = styled.div`
 display: flex;
 flex-direction: column;
`;

export const FarmSelectBase = styled.div<WebProps>`
display: flex;
flex-direction: column;
width: ${({ isMobile }) => (isMobile ? '227px' : '300px')};
height: ${({ isMobile }) => (isMobile ? '41px' : '48px')};
cursor: pointer;
border-radius: 20px;
background-color: rgba(0, 0, 0, 0.8);
margin-left: ${({ isMobile }) => (isMobile ? '14px' : '65px')};
margin-top: ${({ isMobile }) => (isMobile ? '22px' : '7px')};
border: ${({ isSelected }) => (isSelected ? '2px solid #0a95f2' : '1px solid transparent')};
z-index: 2;
`;

export const FarmSelectDiv = styled.div<WebProps>`
display: flex;
flex-direction: row;
position: relative;
top: ${({ isMobile }) => (isMobile ? '7px' : '11px')};
left: 13px;
width: ${({ isMobile }) => (isMobile ? '200px' : '275px')};
`;

export const TextSection = styled.div<WebProps>`
  margin-left: 8px;
  width: ${({ isMobile }) => (isMobile ? '154px' : '227px')};
`;

export const Text = styled.div<WebProps>`
 color: ${({ isSelected }) => (isSelected ? 'rgba(255, 255, 255, 1)' : '#4D5165')};
  font-size: 16px;
  white-space: nowrap;
  font-weight: 400;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis; 
`;

export const ImageSection = styled.div<WebProps>`
 margin-top: 1px;
`;

export const BottomArrow = styled.div`
  padding: 3px 0 0 8px;
`;

export const BottomCardBase = styled.div`
  z-index: 2;
  margin-top: 8px;
  width: 300px;
  margin-left: 65px;
  border-radius: 8px;
  padding: 8px;
  background-color: #363948;
  max-height: 158px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
}
`;
