/* eslint-disable indent */
/* eslint-disable array-bracket-newline */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useCallback, useRef, useMemo, forwardRef } from 'react';
import mapboxgl, { LngLat, Style, LngLatBounds } from 'mapbox-gl';
import MapboxGeocoder, { Result } from '@mapbox/mapbox-gl-geocoder';
import { colors } from 'syngenta-digital-cropwise-react-ui-kit';
import { LocationControl } from '../Maps/Controls/LocationControl';
import { CustomControl } from '../Maps/Controls/CustomControl';
import NotificationTextTranslation from 'components/NotificationTextTranslation';
import { CenterMapControl } from '../Maps/Controls/CenterMapControl/CenterMapControl';

import { browserDetector } from 'utils/helpers/browser';
import { MapsConstants } from 'utils/constants/MapsConstants';
import { BrowserConstants } from 'utils/constants/BrowserConstants';

import IconCloseRounded from 'components/Icons/IconCloseRounded';
import { StyledMarker, StyledPin } from 'components/Maps/Maps.styles';
import 'mapbox-gl/dist/mapbox-gl.css';
import './map.less';

import {
  Map as MapReact,
  useMap,
  Marker as MarkerReact,
  MapRef,
  ViewStateChangeEvent,
  Source,
  Layer,
} from 'react-map-gl';

import { booleanContains } from '@turf/turf';

import DrawableMap from '../DrawableMap/DrawableMap';

import { useAppState, useAppDispatch } from 'context/AppState';
import {
  currentMapCoordinates,
  pointInBbox,
  pointInCountry,
  pointIsInSpecificCountry,
} from 'utils/helpers/geospatial';
import { useBreakpoint } from 'hooks';
import openMapNotification from 'utils/openMapNotification';
import { useTranslation } from 'react-i18next';
import { useFlowActions } from 'context/actions/flowActions';
import { useMapActions } from 'context/actions/mapActions';
import { useRecommendationFormActions } from 'context/actions/recommendationFormActions';
import createCircleFeild from 'utils/createCircleField';

import { FlowSteps, ModeTypes, OptionType } from 'context/store/flowReducer';

import MapToolsWrapper from 'containers/MapToolsWrapper';
import { ActionTypes, useApiDataActions } from 'context/actions/ApiDataActions';
import { Spinner } from 'components/Spinner';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { ErrorsTypeMap } from 'utils/constants/ErrorsType';
import { StepsControl } from '../Maps/Controls/StepsControl';
import { notification } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Hands from 'assets/icons/hands.svg';
import ProxyLayer from 'base/api/ProxyLayer';
import { processGeocodingResult } from 'utils/helpers/geocodingResult';
import { isEnoughZoomForDetectField } from 'utils/isEnoughZoomLevelToStartWith';

interface IProps {
  mapId: string;
  zoom?: number;
  latitude?: number;
  mapStyle?: string | Style;
  longitude?: number;
  minZoom?: number;
  isLocationEnabled?: any;
  maxZoom?: number;
  onMove?: () => void;
  stepsControlProps?: any;
  drawStepControlProps?: any;
  showPin?: boolean;
  mapLib?: any;
}

const browserDetected = browserDetector();
let locationBaseURL = BrowserConstants.CHROME_LOCATION_URL;
switch (browserDetected) {
  case BrowserConstants.FIREFOX:
    locationBaseURL = BrowserConstants.FIREFOX_LOCATION_URL;
    break;
  case BrowserConstants.EDGE:
    locationBaseURL = BrowserConstants.EDGE_LOCATION_URL;
    break;
  case BrowserConstants.SAFARI:
    locationBaseURL = BrowserConstants.SAFARI_LOCATION_URL;
    break;
  case BrowserConstants.OPERA:
    locationBaseURL = BrowserConstants.OPERA_LOCATION_URL;
    break;
  default:
    break;
}

const DrawAndDropMap = (
  {
    mapId,
    latitude,
    longitude,
    minZoom = 0,
    maxZoom = 22,
    mapStyle = MapsConstants.MAP_STYLE_URL,
    onMove,
    stepsControlProps,
    drawStepControlProps,
    mapLib,
    isLocationEnabled,
  }: IProps,
  ref: React.Ref<MapRef>
): JSX.Element => {
  const { setMapToolsOptionSelected, setUserDropPinPosition } = useMapActions();
  const {
    setShowEnvironmentPopup,
    setIsMapBounded,
    setOptionType,
    setDetectFieldSelectedIds,
    setSelectedFieldMapData,
    setTempDetectFieldSelected,
    setShowInvalidBoundaryModal,
  } = useFlowActions();
  const {
    flow,
    recommendationForm: {
      fields,
      fieldIdSelected,
      tempBoundaryField,
      focusFieldId,
      fieldSelected,
      tempFields,
      isAtleastAVertex,
      pin,
      countryCode,
    },
    apiData: {
      fieldSoilsLoading,
      arableLandLoading,
      fieldSoilsError,
      detectFieldsLoading,
      formattedDetectFieldsData,
    },
    map: { userDropPinPosition },
  } = useAppState();
  const { setCountryCode } = useRecommendationFormActions();
  const { setUserCountryCode } = useFlowActions();

  const {
    showEnvironmentInformationModal,
    isDropAPinFlow,
    showFieldForm,
    currentModeType,
    isMapBounded,
    showEnvironmentPopup,
    isDrawingBoundaries,
    currentStep,
    showInvalidBoundaryModal,
    optionType,
    showFieldInformationSheet,
    detectFieldSelectedIds,
    selectedFieldMapData,
    tempDetectFieldSelected,
  } = flow;

  const [t, i18n] = useTranslation();
  const appDispatcher = useAppDispatch();
  const screens = useBreakpoint();
  const { isMobile, portrait, landscape } = screens;

  const [intersectLocation, setIntersectLocation] = useState(false);
  const [isFieldsFitBoundsBlocked, setIsFieldsFitBoundsBlocked] = useState(false);
  const [showUserMarker, setShowUserMarker] = useState(false);
  const [centerMapButtonDisable, setCenterMapButtonDisable] = useState(true);
  const [latitudeUser, setLatitudeUser] = useState(MapsConstants.LATITUDE_DEFAULT);
  const geocoderRef = useRef<MapboxGeocoder | null>(null);
  const ApiDataActions = useApiDataActions();
  const [displayControls, setDisplayControls] = useState(false);
  const [longitudeUser, setLongitudeUser] = useState(MapsConstants.LONGITUDE_DEFAULT);
  const [centerMapEnabled, setCenterMapEnabled] = useState(false);
  const [map, setMap] = useState<MapRef>();
  const [userLocation, setUserLocation] = useState<LngLat | null>();
  const [coordsEvents, setCoordsEvents] = useState<{
    clicked?: mapboxgl.MapMouseEvent & mapboxgl.EventData;
    cursor?: mapboxgl.MapMouseEvent & mapboxgl.EventData;
  }>();
  const [hasUserZoomedInOnce, setHasUserZoomedInOnce] = useState(false);
  const [hasContainedPolygon, setHasContainedPolygon] = useState(false);

  const flags = useFlags();
  const areExistingFieldsFocusedOnce = useRef(false);
  const isBrazilEnable = flags.isBrazilEnable;
  let polygon;

  const tempBoundaryReference = useRef<number>(0);
  const detectFieldAPICallCount = useRef<number>(0);
  useEffect(() => {
    if (
      currentModeType !== ModeTypes.DELETING &&
      currentModeType !== ModeTypes.EDITING &&
      tempBoundaryField &&
      tempBoundaryReference.current !== tempBoundaryField?.properties?.id &&
      !showEnvironmentPopup
    ) {
      const [firstNode] = tempBoundaryField?.geometry.coordinates[0] || [];
      const [lng, lat] = firstNode;
      const countryCode = pointInCountry({ lng, lat }, flags);
      setShowEnvironmentPopup({ show: true });
      console.log('countryCode DrawAndDropMap', countryCode);
      setCountryCode({ countryCode: countryCode ?? '' });
    }
    tempBoundaryReference.current = tempBoundaryField?.properties?.id;
  }, [tempBoundaryField]);

  const mapInteractive = currentStep === FlowSteps.STEP1;
  const { flowMapMobile } = useMap();

  const mapInteractiveProps = {
    zoomEnabled: mapInteractive,
    rotateEnabled: mapInteractive,
    dragPan: mapInteractive,
    scrollEnabled: mapInteractive,
    dragRotate: mapInteractive,
    doubleClickZoom: mapInteractive,
    pitchEnabled: mapInteractive,
  };

  const handleFitBound = (
    shouldRunFitBounds: boolean,
    latLangbounds: mapboxgl.LngLatBoundsLike
  ) => {
    if (shouldRunFitBounds && fieldIdSelected.toString().length === 0) {
      if (isMobile && !landscape) {
        map?.fitBounds(latLangbounds, {
          padding: {
            top: 90,
            bottom: 290,
            left: 50,
            right: 50,
          },
          maxZoom: 16,
        });
      }
    }
  };

  useEffect(() => {
    const latLangbounds = new LngLatBounds();
    let runFitBounds = false;
    // Focus only one field with WIP
    if (
      tempBoundaryField &&
      ((currentModeType === ModeTypes.EDITING && !isMapBounded) ||
        currentModeType === ModeTypes.NO_ACTION)
    ) {
      setIsMapBounded({ bounded: true });
      tempBoundaryField?.geometry.coordinates[0].forEach((coords) => {
        if (coords instanceof Array) {
          latLangbounds.extend(new LngLat(coords[0], coords[1]));
        }
      });
      runFitBounds = true;
    }

    if (
      (fields.length > 0 &&
        !focusFieldId &&
        !isFieldsFitBoundsBlocked &&
        (currentModeType === ModeTypes.SAVING || currentModeType === ModeTypes.NO_ACTION)) ||
      currentStep === FlowSteps.STEP2
    ) {
      if (flow.showEnvironmentInformationModal) {
        const fieldToBeFocus = fieldSelected || fields[fields.length - 1];
        fieldToBeFocus.boundary.geometry.coordinates[0].forEach((coords) => {
          latLangbounds.extend(new LngLat(coords[0], coords[1]));
        });
      } else {
        const allCoordinatesData = fields
          .map((data) => data.boundary.geometry.coordinates)
          .reduce((prev, current) => [...prev, ...current], [])
          .reduce((prev, current) => [...prev, ...current], []);

        allCoordinatesData.forEach((coord: Array<number>) => {
          latLangbounds.extend(new LngLat(coord[0], coord[1]));
        });
      }
      runFitBounds = true;
    }
    handleFitBound(runFitBounds, latLangbounds);
  }, [
    fieldSelected,
    map,
    fields,
    currentModeType,
    tempBoundaryField,
    currentStep,
    portrait,
    isMobile,
    landscape,
    fieldIdSelected,
    showEnvironmentInformationModal,
  ]);
  let hasEnoughZoom = flowMapMobile ? isEnoughZoomForDetectField(flowMapMobile) : true;

  useEffect(() => {
    if (focusFieldId) {
      const latLangbounds = new LngLatBounds();
      const fieldToBeFocus = fields.filter((item) => `${item.id}` === `${focusFieldId}`)[0];
      fieldToBeFocus.boundary.geometry.coordinates[0].forEach((coords) => {
        if (coords instanceof Array) {
          latLangbounds.extend(new LngLat(coords[0], coords[1]));
        }
      });

      map?.fitBounds(latLangbounds, {
        padding: {
          top: 90,
          bottom: 290,
          left: 50,
          right: 50,
        },
        maxZoom: 16,
      });
      setIsFieldsFitBoundsBlocked(true);
    }
  }, [focusFieldId]);

  useEffect(() => {
    if (
      tempBoundaryField ||
      fields.length > 0 ||
      (optionType === OptionType.Detect && tempFields.length > 0)
    ) {
      setCenterMapButtonDisable(false);
    } else {
      setCenterMapButtonDisable(true);
    }
  }, [fields, tempBoundaryField, tempFields]);

  const displayDrawInfoNotification = useCallback(() => {
    openMapNotification({
      id: 'drawInfoNotification',
      className: 'toast-notification-draw',
      key: 'drawInfoNotification',
      duration: 0,
      msg: t('Tap anywhere to draw a shape'),
      placement: 'top',
      icon: <img src={Hands} alt="No img found" style={{ position: 'absolute' }} />,
      style: {
        color: '#FFFFFF',
        borderRadius: '24px',
        maxWidth: 'max-content',
        height: 'max-content',
        backgroundColor: 'rgba(20, 21, 28)',
        ...(isMobile && {
          backgroundColor: 'rgba(20, 21, 28, 0.9)',
        }),
      },
    });
  }, []);

  const addControls = (map?: MapRef) => {
    map?.addControl(new CustomControl('stepsControl'), 'bottom-left');
    map?.addControl(new CustomControl('locationControlMobile'), 'bottom-left');
    map?.addControl(new CustomControl('centerMapControlMobile'), 'bottom-left');
    map?.addControl(new CustomControl('geocoderControl'), 'top-right');
  };

  const displayZoomInNotification = () => {
    openMapNotification({
      id: 'zoomInNotification',
      key: 'zoomInNotification',
      msg: t('Zoom in to select your fields'),
      duration: 0,
      className: 'toast-notification-select zoomin-notification',
      icon: <></>,
      style: {
        borderRadius: '24px',
        maxWidth: 'max-content',
        height: 'max-content',
        color: '#FFFFFF',
        backgroundColor: 'rgba(20, 21, 28)',
        ...(isMobile && {
          backgroundColor: 'rgba(20, 21, 28, 0.9)',
        }),
      },
    });
  };

  const displayTapOnBoundaryNotification = () => {
    openMapNotification({
      id: 'tapOnBoundaryNotification',
      key: 'tapOnBoundaryNotification',
      msg: t('Tap on boundary(s) to select or deselect'),
      className: 'toast-notification-draw',
      icon: <img src={Hands} alt="No img found" style={{ position: 'absolute' }} />,
      duration: 0,
      style: {
        borderRadius: '24px',
        maxWidth: 'max-content',
        height: 'max-content',
        color: '#FFFFFF',
        backgroundColor: 'rgba(20, 21, 28)',
        ...(isMobile && {
          backgroundColor: 'rgba(20, 21, 28, 0.9)',
        }),
      },
    });
  };

  const displayDetectBoundarySelectedNotification = () => {
    openMapNotification({
      id: 'detectBoundarySelectedNotification',
      key: 'detectBoundarySelectedNotification',
      className: 'toast-notification-select',
      msg:
        selectedFieldMapData.features.length === 1
          ? t('boundary selected')
          : t('boundaries selected', { num: selectedFieldMapData.features.length }),
      icon: <></>,
      duration: 0,
      style: {
        borderRadius: '24px',
        color: '#FFFFFF',
        height: 'max-content',
        maxWidth: 'max-content',
        backgroundColor: 'rgba(20, 21, 28)',
        ...(isMobile && {
          backgroundColor: 'rgba(20, 21, 28, 0.9)',
        }),
      },
    });
  };

  useEffect(() => {
    if (isAtleastAVertex || currentModeType !== ModeTypes.CREATING || showFieldInformationSheet) {
      notification.close('drawInfoNotification');
    } else if (!isDropAPinFlow && optionType === OptionType.Draw) {
      displayDrawInfoNotification();
    }
  }, [isAtleastAVertex, currentModeType, i18n.language, isDropAPinFlow]);

  useEffect(() => {
    if (optionType !== OptionType.empty && optionType !== OptionType.Detect) {
      notification.close('selectOptionNotification');
      notification.close('tapOnBoundaryNotification');
      notification.close('detectBoundarySelectedNotification');
    } else if (optionType === OptionType.empty) {
      notification.close('detectBoundarySelectedNotification');
    }
  }, [optionType]);

  const checkIfUserHasZoomedIn = () => {
    if (!hasUserZoomedInOnce) {
      const zoomInNotificationEle = document.getElementsByClassName('zoomin-notification');
      if (zoomInNotificationEle.length > 0) {
        setHasUserZoomedInOnce(true);
      }
    }
  };

  useEffect(() => {
    if (
      optionType === OptionType.Detect &&
      formattedDetectFieldsData?.features.length > 0 &&
      !detectFieldsLoading
    ) {
      if (selectedFieldMapData.features.length === 0) {
        notification.close('selectOptionNotification');
        notification.close('detectBoundarySelectedNotification');
        checkIfUserHasZoomedIn();
        notification.close('zoomInNotification');
        displayTapOnBoundaryNotification();
      } else {
        notification.close('tapOnBoundaryNotification');
        checkIfUserHasZoomedIn();
        notification.close('zoomInNotification');
        notification.close('selectOptionNotification');
        displayDetectBoundarySelectedNotification();
      }
    } else if (!hasEnoughZoom && optionType !== OptionType.Draw && optionType !== OptionType.Drop) {
      notification.close('selectOptionNotification');
      notification.close('tapOnBoundaryNotification');
      !hasUserZoomedInOnce && displayZoomInNotification();
    }
    if (detectFieldsLoading) {
      notification.close('zoomInNotification');
      checkIfUserHasZoomedIn();
    }
  }, [
    formattedDetectFieldsData,
    detectFieldsLoading,
    optionType,
    selectedFieldMapData,
    flowMapMobile?.getZoom(),
  ]);

  const displayTapAnywhereNotification = () => {
    openMapNotification({
      id: 'tapAnywhereNotification',
      className: 'toast-notification-draw',
      msg: t('Tap anywhere to drop a pin'),
      key: 'tapAnywhereNotification',
      duration: 0,
      icon: <img src={Hands} alt="No img found" style={{ position: 'absolute' }} />,
      style: {
        borderRadius: '24px',
        maxWidth: 'max-content',
        height: 'max-content',
        color: '#FFFFFF',
        backgroundColor: 'rgba(20, 21, 28)',
        ...(isMobile && {
          backgroundColor: 'rgba(20, 21, 28, 0.9)',
        }),
      },
    });
  };

  useEffect(() => {
    if (optionType === OptionType.Drop && isDropAPinFlow) {
      displayTapAnywhereNotification();
    } else {
      notification.close('tapAnywhereNotification');
    }
  }, [optionType, isDropAPinFlow, i18n.language]);

  const onUserLocatedFunction = (lngLat: LngLat) => {
    const mapLoaded = flowMapMobile;
    if (centerMapEnabled) {
      setCenterMapEnabled(false);
    }
    if (!showFieldInformationSheet) {
      setLatitudeUser(lngLat.lat);
      setLongitudeUser(lngLat.lng);
      setUserLocation(lngLat);
      setShowUserMarker(true);

      if (flow.isAnotherRecommendationCreated && !areExistingFieldsFocusedOnce.current) {
        mapLoaded?.once('moveend', () => {
          areExistingFieldsFocusedOnce.current = true;
        });
        return;
      }
      const lat = lngLat.lat;
      const lng = lngLat.lng;
      const country = pointInCountry({ lat, lng }, flags);
      setUserCountryCode({
        country: String(country).toLocaleLowerCase().replace(/\s+/g, ''),
      });
      mapLoaded?.flyTo({
        essential: true,
        center: [lngLat.lng, lngLat.lat],
        zoom: 15,
      });
    }
  };

  const handleGeocodingResult = (geocodingResult: Result) => {
    const mapLoaded = flowMapMobile;
    processGeocodingResult(geocodingResult, flags, setUserCountryCode, mapLoaded);
    return '';
  };

  const initializeGeocoding = () => {
    const geoCoder = new MapboxGeocoder({
      accessToken: `${process.env.REACT_APP_MAPBOX_API_KEY}`,
      minLength: MapsConstants.GEOCODER_MIN_LENGHT,
      limit: MapsConstants.GEOCODER_LIMIT_SUGGESTIONS,
      countries: isBrazilEnable
        ? MapsConstants.GEOCODER_COUNTRIES_INCLUDING_BRAZIL
        : MapsConstants.GEOCODER_COUNTRIES,
      language: i18n.language,
      clearOnBlur: true,
      placeholder: t('Search Geocoder'),
      getItemValue: handleGeocodingResult,
    });
    geoCoder.addTo('#geocoderControl');

    geocoderRef.current = geoCoder;

    // replace clear icon
    const geocoderClearButton = document.querySelector(
      '.mapboxgl-ctrl-geocoder--button'
    ) as HTMLElement;
    const icon = document.getElementById('iconClear');
    // eslint-disable-next-line
    geocoderClearButton?.appendChild(icon!);

    const geoCoderInput = document.querySelector('.mapboxgl-ctrl-geocoder--input') as HTMLElement;
    if (geocoderClearButton) {
      geocoderClearButton.style.backgroundColor = '#232630';
    }
    if (geoCoderInput) {
      geoCoderInput.style.color = '#FFF';
      geoCoderInput.style.backgroundColor = '#232630';
      geoCoderInput.style.borderRadius = '4px';
      geoCoderInput.addEventListener('input', () => {
        geocoderClearButton.style.display = 'block';
      });
    }
    geoCoder.on('loading', () => {
      geocoderClearButton.style.display = 'none';
    });

    geoCoder.on('results', () => {
      geocoderClearButton.style.display = 'block';
    });

    geoCoder.on('result', () => {
      if (isMobile) {
        const inputField = document.querySelector(
          '.mapboxgl-ctrl-geocoder--input'
        ) as HTMLInputElement;
        inputField?.blur();
        const closeButtonsArray = Array.from(
          document.querySelectorAll('.syt-antd-notification-notice-close')
        );
        closeButtonsArray.forEach((closeButton) => {
          const element = closeButton as HTMLElement;
          const spanElement = element?.firstChild as HTMLElement;
          spanElement.click();
        });
      }
    });

    if (isMobile) {
      const inputField = document.querySelector(
        '.mapboxgl-ctrl-geocoder--input'
      ) as HTMLInputElement;
      if (inputField) {
        inputField.addEventListener('focus', () => {
          const closeButtonsArray = Array.from(
            document.querySelectorAll('.syt-antd-notification-notice-close')
          );
          closeButtonsArray.forEach((closeButton) => {
            const element = closeButton as HTMLElement;
            const spanElement = element?.firstChild as HTMLElement;
            spanElement.click();
          });
        });
      }
    }
  };
  const isUserAvailableInDetectFieldRegion = () => {
    // TODO: removed the detectFieldEnabledCountry flag temporarily
    const listOfDetectFieldEnabledCountries = 'POL,ROU,HUN,BGR,DEU,UKR,ZAF';
    const center = flowMapMobile?.getCenter();
    if (center) {
      const countryCode = pointInCountry({ lng: center?.lng, lat: center?.lat }, flags, true);
      return countryCode && listOfDetectFieldEnabledCountries.includes(countryCode);
    }
  };

  const detectAvailableFields = async () => {
    // TODO: removed the isDetectFieldFlowEnabled flag temporarily
    // if (!flags.isDetectFieldFlowEnabled) {
    //   return;
    // }
    hasEnoughZoom = flowMapMobile ? isEnoughZoomForDetectField(flowMapMobile) : true;
    const detectFieldEnabledCountry = isUserAvailableInDetectFieldRegion();
    if (
      hasEnoughZoom &&
      detectFieldEnabledCountry &&
      [OptionType.empty, OptionType.Detect].includes(optionType)
    ) {
      if (optionType === OptionType.empty) {
        setOptionType({ type: OptionType.Detect });
      }
      const cornerCoord = flowMapMobile?.getBounds();
      const detectFieldCountry = flowMapMobile?.getCenter();

      if (cornerCoord && detectFieldCountry) {
        const polygon = currentMapCoordinates(cornerCoord);
        const coord = polygon.geometry.coordinates[0];
        const cancelToken = ProxyLayer.cancelToken();

        const digifarmPayload = {
          minLat: cornerCoord.getSouth(),
          minLng: cornerCoord.getWest(),
          maxLat: cornerCoord.getNorth(),
          maxLng: cornerCoord.getEast(),
        };

        const detectCountryCode = pointInCountry(
          { lng: detectFieldCountry?.lng, lat: detectFieldCountry?.lat },
          flags,
          true
        );
        try {
          detectFieldAPICallCount.current = 1;
          const coordinates = [coord];
          const detectFieldPayload = {
            detectCountryCode: detectCountryCode,
            grisPayload: {
              type: 'Polygon',
              coordinates: coordinates,
            },
            digifarmPayload: digifarmPayload,
          };
          const containedPolygon = formattedDetectFieldsData.features.find((feature) => {
            return booleanContains(polygon, feature.geometry);
          });
          if (containedPolygon) {
            setHasContainedPolygon(true);
          } else {
            setHasContainedPolygon(false);
          }
          await ApiDataActions.detectFields(detectFieldPayload, cancelToken.token);
        } catch (e) {
          console.log('Error!!', e);
        } finally {
          cancelToken?.cancel();
          detectFieldAPICallCount.current = 0;
        }
      }
    } else if (formattedDetectFieldsData?.features?.length > 0) {
      appDispatcher({
        type: ActionTypes.setDetectField,
        payload: {
          formattedDetectField: {
            type: 'FeatureCollection',
            features: [],
            isDigifarm: formattedDetectFieldsData.isDigifarm,
          },
        },
      });
    }
  };

  const existingFieldBoundingBox = useMemo(() => {
    const boundsCord = new LngLatBounds();
    if (tempBoundaryField) {
      tempBoundaryField?.geometry.coordinates[0].forEach((coords: Array<number>) => {
        if (coords instanceof Array) {
          boundsCord.extend(new LngLat(coords[0], coords[1]));
        }
      });
    }
    if (fields.length > 0) {
      const allCoordinatesData = fields
        .map((data) => data.boundary.geometry.coordinates)
        .reduce((prev, current) => [...prev, ...current], [])
        .reduce((prev, current) => [...prev, ...current], []);

      allCoordinatesData.forEach((coord: Array<number>) => {
        boundsCord.extend(new LngLat(coord[0], coord[1]));
      });
    }
    if (tempFields.length > 0) {
      const allCoordinatesData = tempFields
        .map((data) => data.boundary.geometry.coordinates)
        .reduce((prev, current) => [...prev, ...current], [])
        .reduce((prev, current) => [...prev, ...current], []);
      allCoordinatesData.forEach((coord: Array<number>) => {
        boundsCord.extend(new LngLat(coord[0], coord[1]));
      });
    }
    if (selectedFieldMapData.features.length > 0) {
      const allCoordinatesData = selectedFieldMapData.features
        .map((data) => data.geometry.coordinates[0])
        .reduce((prev, current) => [...prev, ...current], []);
      allCoordinatesData.forEach((coord: Array<number>) => {
        boundsCord.extend(new LngLat(coord[0], coord[1]));
      });
    }
    return boundsCord;
  }, [fields, tempBoundaryField, tempFields, selectedFieldMapData.features]);

  const handleOnLoadMap = () => {
    const mapLoaded = flowMapMobile;
    setMap(mapLoaded);
    if (detectFieldAPICallCount.current === 0 && !showFieldInformationSheet) {
      detectAvailableFields();
    }
    setDisplayControls(true);
    addControls(mapLoaded);
    initializeGeocoding();
  };

  const onMouseMoveHandler = (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
    e.originalEvent.stopPropagation();
    if (isDropAPinFlow) {
      return;
    }
    setCoordsEvents({ cursor: e });
    !showFieldForm && setMapToolsOptionSelected(0);
  };

  const onDbleClickHandler = (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
    e.originalEvent.stopPropagation();
  };

  const checkIntersection = () => {
    if (!userLocation) {
      return;
    }
    const mapLoaded = flowMapMobile;
    // eslint-disable-next-line
    setIntersectLocation(pointInBbox(userLocation, mapLoaded!.getBounds()));
  };

  const onMoveHandler = (e: ViewStateChangeEvent) => {
    onMove?.();
    if (!flow.arePinConfirmed && userDropPinPosition?.lat !== 0 && userDropPinPosition?.lng !== 0) {
      const position = new mapboxgl.LngLat(e.viewState.longitude, e.viewState.latitude);
      setUserDropPinPosition({ position });
    }
    checkIntersection();
  };

  const onMoveEndHandler = (e: ViewStateChangeEvent) => {
    if (detectFieldAPICallCount.current === 0 && !showFieldInformationSheet) {
      detectAvailableFields();
    }
  };

  const onDragEndHandler = () => {
    if (centerMapEnabled) {
      setCenterMapEnabled(false);
    }
    setIsFieldsFitBoundsBlocked(false);
  };

  if (
    pin.position &&
    flow.arePinConfirmed &&
    process.env.REACT_APP_SHOW_FIELD_AREA === 'true' &&
    isDropAPinFlow
  ) {
    const { lat, lng } = pin.position;
    const center = [lng, lat];
    polygon = createCircleFeild(center, 550);
  }

  const onCenterMapButtonClick = () => {
    const hasFieldCreatedOrSelected =
      tempBoundaryField ||
      fields.length > 0 ||
      tempFields.length > 0 ||
      selectedFieldMapData?.features?.length > 0;
    if (hasFieldCreatedOrSelected) {
      const bounds = existingFieldBoundingBox;
      map?.fitBounds(bounds, {
        padding: {
          top: 80,
          bottom: 90,
          left: 20,
          right: 20,
        },
        maxZoom: 16,
      });
      setCenterMapEnabled(true);
    }
  };

  const handleOkSoilsError = () => {
    appDispatcher({
      type: ActionTypes.setFieldSoilsError,
      payload: { error: undefined },
    });
  };

  const handleConfirmRelocateToBoundary = () => {
    setShowInvalidBoundaryModal({ show: false });
    onCenterMapButtonClick();
  };

  const drawBoundary = useCallback(({ e }: { e: mapboxgl.MapMouseEvent & mapboxgl.EventData }) => {
    setCoordsEvents({ clicked: e });
    setMapToolsOptionSelected(0);
    // eslint-disable-next-line
  }, []);

  const getSelectedDetectField = (uniqueId: string | undefined) => {
    if (uniqueId) {
      return formattedDetectFieldsData.features.find(
        (item) => item.properties.fieldUniqueId === uniqueId
      );
    }
  };

  const handleDetectFieldClick = (
    featureUniqueId: string | undefined,
    e: mapboxgl.MapMouseEvent & mapboxgl.EventData
  ) => {
    const isFieldSaved = fields.find((item) => item.detectFielduniqueId === featureUniqueId);
    if (isFieldSaved) {
      return;
    }
    const isFieldSelected = selectedFieldMapData.features.find(
      (item) => item.properties.fieldUniqueId === featureUniqueId
    );
    if (isFieldSelected) {
      setSelectedFieldMapData({
        data: {
          type: 'FeatureCollection',
          features: selectedFieldMapData.features.filter(
            (item) => item.properties.fieldUniqueId !== featureUniqueId
          ),
          isDigifarm: formattedDetectFieldsData.isDigifarm,
        },
      });
      setDetectFieldSelectedIds({
        data: detectFieldSelectedIds.filter((item) => item !== featureUniqueId),
      });
      setTempDetectFieldSelected({
        selected: tempDetectFieldSelected.filter((item) => item !== featureUniqueId),
      });
      return;
    }
    const selectedPolygon = getSelectedDetectField(featureUniqueId);
    if (
      selectedFieldMapData?.features?.length > 0 &&
      selectedPolygon?.geometry &&
      !pointIsInSpecificCountry(e.lngLat, countryCode ?? '')
    ) {
      setShowInvalidBoundaryModal({ show: true });
      return;
    }
    if (selectedPolygon && featureUniqueId) {
      setSelectedFieldMapData({
        data: {
          type: 'FeatureCollection',
          features: [...selectedFieldMapData.features, selectedPolygon],
          isDigifarm: formattedDetectFieldsData.isDigifarm,
        },
      });
      setDetectFieldSelectedIds({ data: [...detectFieldSelectedIds, featureUniqueId] });
      setTempDetectFieldSelected({ selected: [...tempDetectFieldSelected, featureUniqueId] });
      if (countryCode === '') {
        const { lng, lat } = e.lngLat;
        const code = pointInCountry({ lng, lat }, flags, true) ?? '';
        setCountryCode({ countryCode: code });
      }
    }
  };

  const onClickHandler = (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
    e.originalEvent.stopPropagation();
    let featureUniqueId = undefined;
    if (isDropAPinFlow) {
      const position = e.lngLat;
      setUserDropPinPosition({ position });
      notification.close('tapAnywhereNotification');
      return;
    }
    if (optionType === OptionType.Detect && formattedDetectFieldsData.features.length > 0) {
      const featureUnselected = map?.queryRenderedFeatures(e.point, {
        layers: ['fields-fill'],
      });
      const featureSelected = map?.queryRenderedFeatures(e.point, {
        layers: ['selected-fields-fill'],
      });
      if (featureSelected && featureSelected.length > 0) {
        featureUniqueId = featureSelected[0].properties?.fieldUniqueId;
      }
      if (featureUnselected && featureUnselected.length > 0) {
        featureUniqueId = featureUnselected[0].properties?.fieldUniqueId;
      }
    }
    setCenterMapEnabled(false);
    if (flow.isAnotherRecommendationCreated) {
      return;
    }
    if (currentModeType === ModeTypes.CREATING || currentModeType === ModeTypes.EDITING) {
      drawBoundary({ e });
    } else {
      handleDetectFieldClick(featureUniqueId, e);
    }
  };

  const checkUserIsInDifferentLocation = useMemo(() => {
    return (
      !isDrawingBoundaries &&
      !showInvalidBoundaryModal &&
      fields.length > 0 &&
      currentModeType === ModeTypes.SAVING
    );
  }, [showInvalidBoundaryModal]);

  useEffect(() => {
    geocoderRef.current?.setPlaceholder(t('Search Geocoder'));
    geocoderRef.current?.setLanguage(i18n.language);
  }, [i18n.language, t]);

  useEffect(() => {
    if (checkUserIsInDifferentLocation) {
      setCenterMapEnabled(false);
      onCenterMapButtonClick();
    }
  }, [
    centerMapEnabled,
    showInvalidBoundaryModal,
    flow.isAnotherRecommendationCreated,
    currentStep,
    flow.mobileActiveTab,
  ]);

  const centerMap = () => {
    if (showFieldInformationSheet && !existingFieldBoundingBox.isEmpty()) {
      return existingFieldBoundingBox.getCenter();
    } else if (flow.isAnotherRecommendationCreated && !existingFieldBoundingBox.isEmpty()) {
      return existingFieldBoundingBox.getCenter();
    } else {
      return { lat: latitude, lng: longitude };
    }
  };

  const center = centerMap();

  const initialViewState = {
    longitude: center.lng,
    latitude: center.lat,
    zoom: 9,
  };

  return (
    <MapReact
      ref={ref}
      id={mapId}
      onDblClick={onDbleClickHandler}
      onClick={onClickHandler}
      onLoad={handleOnLoadMap}
      onMouseMove={onMouseMoveHandler}
      onDragEnd={onDragEndHandler}
      onMove={onMoveHandler}
      onMoveEnd={onMoveEndHandler}
      maxZoom={maxZoom}
      minZoom={minZoom}
      initialViewState={initialViewState}
      style={{ flexGrow: 1 }}
      // cursor="crosshair"
      mapStyle={mapStyle}
      mapLib={mapLib}
      {...mapInteractiveProps}
    >
      <div data-testid="mapContainer" style={{ display: displayControls ? 'block' : 'none' }}>
        {!isDropAPinFlow && (
          <DrawableMap
            clickedCoordEvent={coordsEvents?.clicked}
            cursorCoordEvent={coordsEvents?.cursor}
          />
        )}
        <MapToolsWrapper />
        <StepsControl
          id="stepsControl"
          buttonNextProps={
            isDropAPinFlow
              ? stepsControlProps?.buttonNextProps
              : drawStepControlProps?.buttonNextProps
          }
          buttonPreviousProps={
            isDropAPinFlow
              ? stepsControlProps?.buttonPreviousProps
              : drawStepControlProps?.buttonPreviousProps
          }
        />
        <LocationControl
          buttonProps={{
            id: 'locationControlMobile',
            'aria-label': 'Find my location',
          }}
          onUserLocated={(position: LngLat) => onUserLocatedFunction(position)}
          isLocationEnabled={Boolean(isLocationEnabled)}
          mapIntersected={intersectLocation}
          tooltipProps={{
            title: (
              <>
                <NotificationTextTranslation
                  text={'Your precise location could not be determined.'}
                />
                <br />
                <a target="_blank" rel="noreferrer" href={locationBaseURL}>
                  <NotificationTextTranslation text={'LEARN MORE'} />
                </a>
              </>
            ),
            overlayInnerStyle: { maxWidth: '171px' },
          }}
        />
        <CenterMapControl
          id="centerMapControlMobile"
          isDisabled={centerMapButtonDisable}
          onCenterMapButtonClick={onCenterMapButtonClick}
          isActive={centerMapEnabled}
        />
        <div
          id="geocoderControl"
          style={{ display: flow.currentStep === FlowSteps.STEP1 ? 'block' : 'none' }}
        >
          <IconCloseRounded id="iconClear" />
        </div>
      </div>
      {showUserMarker && (
        <MarkerReact style={{ zIndex: 1 }} latitude={latitudeUser} longitude={longitudeUser}>
          <StyledMarker />
        </MarkerReact>
      )}
      {polygon && (
        <Source id={'circle'} type="geojson" data={polygon}>
          <Layer
            type="fill"
            source="circle-field"
            paint={{ 'fill-color': colors.blue40, 'fill-opacity': 0.3 }}
          />
          <Layer
            type="line"
            source="circle-field-line"
            paint={{ 'line-color': colors.blue50, 'line-width': 2 }}
          />
        </Source>
      )}
      {fieldSoilsLoading && mapInteractive && <Spinner data-testid="soil-api-spinner" />}
      {arableLandLoading && currentModeType === ModeTypes.CREATING && (
        <Spinner data-testid="arable-api-spinner" />
      )}
      {detectFieldsLoading && !hasContainedPolygon && <Spinner hideSpinner={true} />}
      {fieldSoilsError && (
        <ConfirmationModal
          title={t(ErrorsTypeMap.MAX_ATTEMPTS.statusTitle ?? '')}
          body={t(ErrorsTypeMap.MAX_ATTEMPTS.statusMessage)}
          confirmButtonText={t('Ok')}
          onClickConfirm={handleOkSoilsError}
        />
      )}
      {showInvalidBoundaryModal && (
        <ConfirmationModal
          title={t('Invalid Boundary')}
          body={t(
            'We are unable to add this boundary. Please ensure that all boundaries are located within your region/country.'
          )}
          confirmButtonText={t('Locate existing boundaries')}
          onClickConfirm={handleConfirmRelocateToBoundary}
        />
      )}
      {isDropAPinFlow && (
        <MarkerReact
          style={{ zIndex: 10 }}
          latitude={!flow.arePinConfirmed ? userDropPinPosition.lat : pin.position?.lat}
          longitude={!flow.arePinConfirmed ? userDropPinPosition.lng : pin.position?.lng}
        >
          <StyledPin src={flow.localCountry} className="animate-drop-pin" />
        </MarkerReact>
      )}
    </MapReact>
  );
};
export default forwardRef(DrawAndDropMap);
