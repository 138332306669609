import breakpoints from 'base/constants/breakpoints';
import styled from 'styled-components';
import { SYT_PREFIX, colors, Radio, Menu } from 'syngenta-digital-cropwise-react-ui-kit';

export const YearRangeHeading = styled.div`
  display: flex;
  font-family: 'Noto Sans';
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  gap: 8px;
  color: ${colors.neutral100};
`;

export const AbioticStressStyle = styled.div`
  margin-left: 24px;

  @media screen and ${breakpoints.maxWidth.sm} {
    margin-left: 0px;
  }
`;

export const StyledInfoBubble = styled.div`
  & + div .${SYT_PREFIX}-tooltip-inner {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.0075em;
    color: ${colors.neutral100};
    padding: 6px 10px;
    gap: 10px;
    align-self: stretch;
    opacity: 1;
    width: 307px;
    flex-direction: column;
    align-items: flex-end;
  }
`;

export const OptionTexture = styled.div`
  display: flex;
  align-items: center;
  margin-left: 25px;
  font-size: 15px;
`;

export const StyledRadioGroup = styled(Radio.Group)`
  display: flex;
  padding: 0px 8px;

  @media screen and ${breakpoints.maxWidth.sm} {
    display: inline-block;
    width: 100%;
    padding: 0px 0px 0px 8px;
  }

  .syt-antd-radio {
    top: 0 !important;
  }

  .syt-antd-radio-wrapper {
    font-size: 14px;
    .syt-antd-radio-inner {
      &::after {
        left: 7px;
        top: 7px;
      }
    }
    .syt-antd-radio + * {
      display: flex;
      padding-right: 0px;
      gap: 8px;
    }

    .syt-antd-radio-inner {
      border-color: ${colors.neutral30};
      width: 20px;
      height: 20px;
    }
    .syt-antd-radio: focus-within .syt-antd-radio-inner {
      box-shadow: none;
    }
  }
`;

export const StyledRadio = styled(Radio)`
  display: flex;
  align-items: center;
  font-size: 14px;

  .syt-antd-radio-inner {
    &::after {
      top: 50% !important;
      left: 50% !important;
    }
  }

  .syt-antd-radio-checked .syt-antd-radio-inner {
    border-color: ${colors.blue60};
    &::after {
      background-color: ${colors.blue60};
    }
  }

  @media screen and ${breakpoints.maxWidth.sm} {
    margin: 8px 0px;
  }
`;

export const StressContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and ${breakpoints.maxWidth.sm} {
    margin-bottom: 8px;
    width: 100%;
  }
`;

export const DropdownContainer = styled.div`
  display: flex;
  gap: 8px;
  @media screen and ${breakpoints.maxWidth.sm} {
    align-items: center;
  }
`;

export const StressTypeLabel = styled.span`
  display: flex;
  font-family: 'Noto Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.105px;
`;

export const StyledMenu = styled(Menu)`
  padding: 0;
  background-color: ${colors.neutral00};
  box-shadow: 0px 2px 8px ${colors.blue10};
  border-radius: 8px;

  .syt-antd-radio {
    top: 0 !important;
  }
  @media screen and ${breakpoints.maxWidth.sm} {
    margin-left: 25px !important;
  }
`;

export const StyledMenuItem = styled(Menu.Item)`
  list-style-type: none;
  font-size: 14px;
  text-align: right;
  padding: 10px 105px 10px 12px;
  height: 28px;
  background-color: ${colors.neutral00} !important;
  border-bottom: 1px solid ${colors.neutral10};

  .syt-antd-radio-inner::after {
    top: 50% !important;
    left: 50% !important;
  }

  &:hover {
    background-color: ${colors.blue10} !important;
    cursor: pointer;
  }
  &:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-bottom: none;
  }

  @media screen and ${breakpoints.maxWidth.sm} {
    padding: 20px 170px 20px 12px;
    width: 100%;
  }
`;

export const DropDownIcon = styled.div<{
  isDisable?: boolean;
}>`
  display: flex;
  width: 24px;
  height: 24px;
  background: ${(props) => (props.isDisable ? 'none' : `${colors.neutral10}`)};
  border: ${(props) => (props.isDisable ? 'none' : `1px solid ${colors.neutral10}`)};
  border-radius: 4px;

  img {
    padding: 3px;
  }

  &:hover {
    cursor: ${(props) => (props.isDisable ? 'not-allowed' : `pointer`)};
  }
`;

export const SelectedStressLabel = styled.div`
  display: flex;
  padding: 0px 8px;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  border: 1px solid ${colors.neutral10};
  background: ${colors.neutral10};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.09px;
  color: ${colors.blue60};
  height: 24px;

  @media screen and ${breakpoints.maxWidth.sm} {
    height: 20px;
  }
`;

export const Divider = styled.div`
  display: flex;
  width: 13px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  color: ${colors.neutral10};
  margin: 16px;
  border-bottom: 1px solid #dfe2e7;
  rotate: 90deg;
`;

export const TooltipText = styled.div`
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.105px;
  margin-bottom: 20px;

  p {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  strong {
    font-weight: 600;
  }
`;
