import React, { useMemo, useEffect } from 'react';
import {
  AbioticStressStyle,
  Divider,
  DropDownIcon,
  SelectedStressLabel,
  StressContainer,
  StressTypeLabel,
  StyledInfoBubble,
  StyledRadio,
  StyledRadioGroup,
  TooltipText,
  YearRangeHeading,
  DropdownContainer,
} from './AbioticStressStyle';
import { useTranslation } from 'react-i18next';
import InfoBubble from 'components/InfoBubble';
import { colors } from 'syngenta-digital-cropwise-react-ui-kit';
import down_arrow from 'assets/icons/down_arrow.svg';
import AbioticDropdown from './AbioticDropDown';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import { RadioChangeEvent } from 'antd';
import { useAppState } from 'context/AppState';
import { useFlowActions } from 'context/actions/flowActions';
import { StressType } from 'context/store/flowReducer';

const TooltipContent = () => {
  const { t } = useTranslation();
  return (
    <TooltipText>
      <p>
        <strong>{t('Stress Frequency')}</strong> {t('stressFrequencyDescription')}
      </p>
      <p>
        <strong>{t('Stress Intensity')}</strong> {t('stressIntensityDescription')}
      </p>
    </TooltipText>
  );
};

const AbioticStressSection: React.FC = () => {
  const currentDate = moment();
  const {
    flow: { abioticStageStressType, selectedAbioticFrequencyValue, selectedAbioticIntensityValue },
  } = useAppState();

  const { setAbioticStageStressType, setAbioticFrequencyValue, setAbioticIntensityValue } =
    useFlowActions();

  const { t } = useTranslation();
  const frequencyOptions = [
    { key: 'last20', label: t('Last 20 years') },
    { key: 'last10', label: t('Last 10 years') },
  ];

  const getLastThreeYears = useMemo(() => {
    const currentYear = currentDate.year();
    return [currentYear - 1, currentYear - 2, currentYear - 3].map((year) => ({
      key: year.toString(),
      label: year.toString(),
    }));
  }, [currentDate]);

  useEffect(() => {
    if (!selectedAbioticFrequencyValue) {
      setAbioticFrequencyValue({ year: frequencyOptions[0].key });
    }
    if (!selectedAbioticIntensityValue) {
      setAbioticIntensityValue({ year: getLastThreeYears[0].key });
    }
  }, [getLastThreeYears]);

  const handleStressTypeChange = (newStressType: StressType) => {
    setAbioticStageStressType({ stressType: newStressType });
  };
  return (
    <AbioticStressStyle>
      <YearRangeHeading>
        {t('Weather data year range by:')}
        <StyledInfoBubble>
          <InfoBubble
            width={20}
            height={20}
            title={<TooltipContent />}
            className="weather-data-year-range-by-tooltip"
            iconColor={colors.neutral60}
          />
        </StyledInfoBubble>
      </YearRangeHeading>

      <StyledRadioGroup data-testid="stress-type" value={abioticStageStressType}>
        <StressContainer>
          <StyledRadio
            value={StressType.Frequency}
            onChange={(e: RadioChangeEvent) => handleStressTypeChange(e.target.value as StressType)}
          >
            <StressTypeLabel className="radioOptionLabel">
              {t(StressType.Frequency)}
            </StressTypeLabel>
          </StyledRadio>
          <DropdownContainer>
            {abioticStageStressType === StressType.Frequency && (
              <SelectedStressLabel>
                {
                  frequencyOptions.find((option) => option.key === selectedAbioticFrequencyValue)
                    ?.label
                }
              </SelectedStressLabel>
            )}
            <AbioticDropdown
              options={frequencyOptions}
              onChange={(key) => setAbioticFrequencyValue({ year: key })}
              selectedOption={selectedAbioticFrequencyValue ?? ''}
              isDisable={abioticStageStressType !== StressType.Frequency}
            >
              <DropDownIcon isDisable={abioticStageStressType !== StressType.Frequency}>
                <img src={down_arrow} alt="" />
              </DropDownIcon>
            </AbioticDropdown>
          </DropdownContainer>
        </StressContainer>

        {!isMobile && <Divider />}
        <StressContainer>
          <StyledRadio
            value={StressType.Intensity}
            onChange={(e: RadioChangeEvent) => handleStressTypeChange(e.target.value as StressType)}
          >
            <StressTypeLabel className="radioOptionLabel">
              {t(StressType.Intensity)}
            </StressTypeLabel>
          </StyledRadio>
          <DropdownContainer>
            {abioticStageStressType === StressType.Intensity && (
              <SelectedStressLabel>
                {
                  getLastThreeYears.find((option) => option.key === selectedAbioticIntensityValue)
                    ?.label
                }
              </SelectedStressLabel>
            )}
            <AbioticDropdown
              options={getLastThreeYears}
              onChange={(key) => setAbioticIntensityValue({ year: key })}
              selectedOption={selectedAbioticIntensityValue ?? ''}
              isDisable={abioticStageStressType !== StressType.Intensity}
            >
              <DropDownIcon isDisable={abioticStageStressType !== StressType.Intensity}>
                <img src={down_arrow} alt="" />
              </DropDownIcon>
            </AbioticDropdown>
          </DropdownContainer>
        </StressContainer>
      </StyledRadioGroup>
    </AbioticStressStyle>
  );
};

export default AbioticStressSection;
