import { useNavigate, useRouteError } from 'react-router-dom';

import { Root, Content, ButtonStyled, Title, Image, Message } from './ErrorPage.styles';
import errorPageImage from '../../assets/images/error-page.svg';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppState } from 'context/AppState';
import { captureExceptionInSentry } from '../../utils';
import { useEffect, useState } from 'react';

interface ErrorPageProps {
  type?: 'error' | 'message';
  [key: string]: any;
  isRecommendationPage?: boolean;
}

export default function ErrorPage(props: ErrorPageProps) {
  const appDispatcher = useAppDispatch();
  const {
    flow: { requestError },
    apiData: { recommendationCropwise },
  } = useAppState();
  const navigate = useNavigate();
  const { type = 'message', isRecommendationPage = false } = props;
  const [showButton, setShowButton] = useState(true);
  const error: any = useRouteError();
  const { t } = useTranslation();
  const title =
    type === 'error'
      ? `Error ${error?.status || error?.message || ''}`
      : `404 - ${t('Page not found')}`;

  const userFriendlyErrorMsg = requestError
    ? `${requestError?.status} - ${t(requestError.statusMessage)}`
    : title;
  captureExceptionInSentry(userFriendlyErrorMsg);

  const gotoHome = () => {
    appDispatcher({ type: 'reset-app' });
    navigate('/');
  };

  const titleMessage =
    type === 'error' ? t('Please try again later') : `404 - ${t('Page not found')}`;

  useEffect(() => {
    if (!recommendationCropwise?.is_ddg && isRecommendationPage) {
      setShowButton(false);
    }
  }, [isRecommendationPage, recommendationCropwise]);

  return (
    <Root>
      <Content>
        <Title>{titleMessage}</Title>
        <Image src={errorPageImage} />
        <Message>{t('We are sorry, the page you requested could not be found!')}</Message>
        {showButton && (
          <ButtonStyled type="primary" size="large" onClick={gotoHome}>
            {t('Back to Home')}
          </ButtonStyled>
        )}
      </Content>
    </Root>
  );
}
