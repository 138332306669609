/* eslint-disable indent */
/* eslint-disable array-bracket-newline */
import { useEffect, useState, useCallback, useMemo } from 'react';
import { Skeleton, colors } from 'syngenta-digital-cropwise-react-ui-kit';
import { useTranslation } from 'react-i18next';
import { useAppState } from 'context/AppState';
import { MainSectionContent, StyledTextInfo } from './ExpertSeedAdvice.styles';
import { ProductsContainer, DisclaimerContentText } from './styles';
import SeedCard, { IProps as SeedCardProps } from './SeedCard';
import { ProductSeed } from 'base/types/Products';
import { getTraitLabel } from 'utils/trait';
import { CropConstants } from 'utils/constants/Crop';
import { averageYieldConvertedValue, seedRateConvertedValue } from 'utils/helpers/converter';
import {
  SkeletonRowStyled,
  SkeletonTitleStyled,
  SkeletonMobileContainer,
  SkeletonMobileBottomContainer,
  SkeletonMobileTopContainer,
} from '../SoilCharacteristics.styles';
import { BreakoutsProductsSummaryDetails } from 'base/types/BreakoutsProductsSummaryResponse';
import {
  ExpertSeedAdviceTitle,
  LinkContainer,
  SectionContainer,
  StyledTooltip,
  Text,
  TooltipContainer,
  TooltipLink,
  TooltipText,
  TooltipTitle,
  TooltipValueText,
} from 'pages/RecommendationV2/V2Common.styles';
import BottomSheet from '../AgronomicPreferencesSheet/AgronomicPreferencesSheet';
import { getDataForSouthAfrica } from 'utils/getSeedRateAndBagSizeForSouthAfrica';

interface IProps {
  readonly sectionRef?: any;
  readonly isMobile?: boolean;
  readonly isLoading?: boolean;
  readonly isExpertSeedAdvice?: boolean;
  readonly isSecondSection?: boolean;
  readonly onClickToSeeDetails?: () => void;
  readonly handleOnClick?: (productName: string) => void;
}
export default function ExpertSeedAdvice({
  sectionRef,
  isLoading,
  isMobile = false,
  onClickToSeeDetails,
  handleOnClick,
}: IProps): JSX.Element {
  const { t } = useTranslation();
  const {
    apiData: {
      recommendationCropwise,
      productCatalog: productsByCropAndCountry,
      breakoutsGlobal,
      breakoutsGlobalWithFilters,
      breakoutsProductsSummary,
      breakoutsProductsSummaryWithFilters,
      countryList,
    },
  } = useAppState();

  const [products, setProducts] = useState<SeedCardProps[]>([]);
  const [showAgroPreferenceSheet, setShowAgroPreferenceSheet] = useState<boolean>(false);

  const cropName = recommendationCropwise?.recommendations[0].crop_name ?? '';
  const agronomicInputs =
    recommendationCropwise?.recommendations[0]?.multi_field?.config?.agronomicInputs;
  const {
    yieldRange,
    rotation: plantingPeriod,
    tillage: tillagePractice,
    spatialClass,
  } = agronomicInputs ?? {};
  const isDDG = recommendationCropwise?.is_ddg;
  const yieldUnit = agronomicInputs?.extraInfo?.yieldRangeUnit ?? '';
  const calculateSeedRate = (country: string | undefined, seedRate: string, product: string) => {
    if (country === CropConstants.SOUTH_AFRICA) {
      return getDataForSouthAfrica(country, spatialClass, product, countryList, true);
    } else {
      return seedRateConvertedValue(country, seedRate);
    }
  };

  const generateDescription = (country: string, dataList: string) => {
    if (country === CropConstants.SOUTH_AFRICA) {
      return dataList?.split('•').filter((data) => data !== '');
    }
    return dataList;
  };

  useEffect(() => {
    if (
      !productsByCropAndCountry.length ||
      !Object.keys(breakoutsGlobal).length ||
      !Object.keys(breakoutsGlobalWithFilters).length ||
      !breakoutsProductsSummary.length ||
      !breakoutsProductsSummaryWithFilters.length ||
      !recommendationCropwise ||
      !Object.keys(recommendationCropwise).length
    ) {
      return;
    }
    const productsByCropAndCountryMapByProductName: { [key: string]: ProductSeed } =
      productsByCropAndCountry.reduce(
        (accumulator, product) => ({
          ...accumulator,
          [product.productName]: { ...product },
        }),
        {}
      );

    const recommendedProducts = recommendationCropwise?.recommendations[0].products;

    const country = recommendationCropwise?.country.trim();
    const recommendationProducts = recommendedProducts.filter(
      (seed) => seed.product_name !== 'None'
    );

    const productsFormatted: SeedCardProps[] = recommendationProducts.map((product, index) => {
      const productMapped = productsByCropAndCountryMapByProductName[product.product_name] || {
        commercialName: '',
      };

      const productsSummary = breakoutsProductsSummary?.find(
        (item: BreakoutsProductsSummaryDetails) => item.entry === product.product_name
      );
      const productsSummaryFiltered = breakoutsProductsSummaryWithFilters?.find(
        (item: BreakoutsProductsSummaryDetails) => item.entry === product.product_name
      );

      const avgYield = productsSummary?.balancedYieldAverage
        ? productsSummary?.balancedYieldAverage
        : productsSummary?.avgYieldSummary;

      const data = {
        productName: productMapped.productName,
        fao: productMapped.fao,
        commercialName: productMapped.commercialName,
        seedRate: calculateSeedRate(
          country,
          productMapped.defaultSeedingRate,
          product?.commercial_name ?? ''
        ),
        description: generateDescription(country, productMapped.positioning),
        avgYieldSummary: averageYieldConvertedValue(
          country,
          avgYield,
          recommendationCropwise?.recommendations[0]?.crop_name
        ),
        plusMinCheck: productsSummary?.plusMinCheck,
        winSummary: productsSummaryFiltered?.winSummary,
        locationsYieldData: productsSummaryFiltered?.locationsSummary,
        locationSummary: productsSummary?.locationsSummary,
        isSelected: !index,
        technology: productMapped.technology,
        grainType: productMapped.grainType,
        color: product.color,
        isDDG: isDDG,
        unit: yieldUnit,
      };

      const additionalData = cropName === CropConstants.SUNFLOWER_SMALLCASE && {
        marketSegment: productMapped.segment,
        herbicideTechnology: getTraitLabel(productMapped.trait),
        maturity: productMapped.maturityGroup,
        broomrapeTechnology: productMapped?.broomrape.replace('BR ', ''),
      };

      return {
        ...data,
        ...additionalData,
      };
    });

    setProducts([...productsFormatted]);
  }, [
    productsByCropAndCountry,
    breakoutsGlobal,
    breakoutsGlobalWithFilters,
    breakoutsProductsSummary,
    breakoutsProductsSummaryWithFilters,
    recommendationCropwise,
    cropName,
  ]);

  const handleAgroPreferencesClick = (value: boolean) => {
    setShowAgroPreferenceSheet(value);
  };

  const getFieldDetail = (_id: string) => {
    if (recommendationCropwise) {
      const field =
        recommendationCropwise.recommendations[0].multi_field.config.recommendation.find(
          (item) => item.FieldId === _id
        );
      if (field) {
        const product = recommendationCropwise.recommendations[0].products.find(
          (item) => item.product_name === field.SelectedSeed?.SeedName
        );
        return {
          commercialName: product?.commercial_name,
        };
      }
      return undefined;
    }
  };

  const additionalFieldDetail =
    recommendationCropwise?.recommendations?.[0]?.multi_field?.geometry?.properties?.map(
      (property) => {
        const fields = property.fields.map((field) => {
          const commercialName = getFieldDetail(field.id)?.commercialName ?? '';
          return { commercialName };
        });
        return { fields };
      }
    );

  const ProductRecommendatedFor = (commercialName: string | undefined) => {
    if (additionalFieldDetail && additionalFieldDetail.length > 0) {
      return additionalFieldDetail
        .map(
          (detail) =>
            detail.fields.filter((field) => field.commercialName === commercialName).length
        )
        .reduce((sum, count) => sum + count, 0);
    }

    return 0;
  };

  const [firstElement, ...remainingElemnts] = products;
  const renderSeedCards = useCallback(() => {
    if (isDDG) {
      return [
        <SeedCard
          isMobile={isMobile}
          key="top-rated"
          cardTitle={`${t('TOP RATED PRODUCT')}`}
          cropName={cropName}
          countryName={recommendationCropwise?.country.trim()}
          index={0}
          handleOnClick={handleOnClick}
          size={products.length}
          {...firstElement}
        />,
        ...remainingElemnts.map((data, index) => (
          <SeedCard
            isMobile={isMobile}
            key={data.productName}
            cardTitle={`${t('ALTERNATIVE PRODUCT')}`}
            cropName={cropName}
            countryName={recommendationCropwise?.country.trim()}
            index={index}
            handleOnClick={handleOnClick}
            size={products.length}
            {...data}
          />
        )),
      ];
    } else {
      return [
        products.map((data, index) => (
          <SeedCard
            isMobile={isMobile}
            key={data.productName}
            cardTitle={`${t('OUR RECOMMENDATION')}`}
            cropName={cropName}
            countryName={recommendationCropwise?.country.trim()}
            index={index}
            handleOnClick={handleOnClick}
            recommendedFieldCount={ProductRecommendatedFor(data.commercialName)}
            size={products.length}
            {...data}
          />
        )),
      ];
    }
  }, [firstElement, remainingElemnts, isMobile, t, cropName]);

  const getUserMsgForFAOAdjustment = useCallback(() => {
    if (agronomicInputs) {
      const productCount = agronomicInputs?.productCountBasedOnUserSelectedFAO;
      if (productCount === 0) {
        return (
          <StyledTextInfo>
            {t(
              'Within the FAO range you provided there have been no products found. We have adjusted the range.'
            )}
          </StyledTextInfo>
        );
      }
      if (productCount === 1 || productCount === 2) {
        return (
          <StyledTextInfo>
            {t(
              'Within the FAO range you provided there have been limited number of products found. We have adjusted the range.'
            )}
          </StyledTextInfo>
        );
      }
    }
  }, [t, agronomicInputs]);

  const getUserMsgForBroomrapeFPlus = useCallback(() => {
    if (agronomicInputs) {
      if (
        agronomicInputs?.broomRapeUserSelection &&
        agronomicInputs?.broomRapeUserSelection[0] === 'F+'
      ) {
        return (
          <StyledTextInfo>
            {t(
              'Please note, that the below sunflower varieties are corresponding to the broomrape type F.'
            )}
          </StyledTextInfo>
        );
      }
    }
  }, [t, agronomicInputs]);

  const SeedCardsContainer = useCallback(() => {
    return (
      <>
        {getUserMsgForFAOAdjustment()}
        {cropName === CropConstants.SUNFLOWER_SMALLCASE && getUserMsgForBroomrapeFPlus()}

        <ProductsContainer center={isMobile ?? products.length < 3}>
          {renderSeedCards()}
        </ProductsContainer>
      </>
    );
  }, [
    isMobile,
    products,
    renderSeedCards,
    getUserMsgForFAOAdjustment,
    getUserMsgForBroomrapeFPlus,
  ]);

  const tooltipProps = {
    title: (
      <TooltipContainer data-testid={'agronomic-inputs-tooltip'}>
        <TooltipTitle>{t('Agronomic Inputs')}:</TooltipTitle>
        <TooltipText>
          {t('Historic yield range')}
          <TooltipValueText>: {yieldRange}</TooltipValueText>
        </TooltipText>
        {plantingPeriod && (
          <TooltipText>
            {t('Planting period')}
            <TooltipValueText>: {plantingPeriod}</TooltipValueText>
          </TooltipText>
        )}
        {tillagePractice && (
          <TooltipText>
            {t('Tillage Practice')}
            <TooltipValueText>: {tillagePractice}</TooltipValueText>
          </TooltipText>
        )}

        <TooltipLink onClick={onClickToSeeDetails}>{t('Click to See Details')}</TooltipLink>
      </TooltipContainer>
    ),
  };

  const getExpertSeedAdviceDescription = (productCount: number) => {
    return (
      <>
        {t(
          'Here are Top products, that have proven their performance under your environmental conditions and match your'
        )}
        &nbsp;
        {isMobile ? (
          <LinkContainer onClick={() => handleAgroPreferencesClick(true)}>
            {t('agronomic preferences.')}
          </LinkContainer>
        ) : (
          <StyledTooltip color={colors.neutral90} placement="topRight" {...tooltipProps}>
            <LinkContainer>{t('agronomic preferences.')}</LinkContainer>
          </StyledTooltip>
        )}
      </>
    );
  };

  const SkeletonLoader = useMemo(() => {
    if (isMobile) {
      return (
        <SkeletonMobileContainer>
          <SkeletonMobileTopContainer>
            <SkeletonTitleStyled width="80%" active size="large" />
          </SkeletonMobileTopContainer>
          <SkeletonMobileBottomContainer>
            <SkeletonRowStyled active size="large" />
          </SkeletonMobileBottomContainer>
        </SkeletonMobileContainer>
      );
    }
    return <Skeleton active paragraph={{ rows: 10, width: '100%' }} />;
  }, [isMobile]);

  return (
    <SectionContainer data-testid="recommended-products-section" ref={sectionRef}>
      <MainSectionContent isHeaderSection={true}>
        <ExpertSeedAdviceTitle>{t('Recommended Products')}</ExpertSeedAdviceTitle>
        {isLoading ? (
          SkeletonLoader
        ) : (
          <Text>{getExpertSeedAdviceDescription(products.length)}</Text>
        )}
      </MainSectionContent>
      {isLoading ? (
        SkeletonLoader
      ) : (
        <>
          {isMobile && showAgroPreferenceSheet && (
            <BottomSheet
              title={t('Agronomic Inputs')}
              onClickOutside={() => handleAgroPreferencesClick(false)}
              onClickViewDetails={() => onClickToSeeDetails && onClickToSeeDetails()}
              yieldRange={yieldRange}
              plantingPeriod={plantingPeriod}
              tillagePractice={tillagePractice}
            />
          )}
          <SectionContainer data-testid="Seed-cards-container">
            {SeedCardsContainer()}
          </SectionContainer>
          <MainSectionContent isHeaderSection={false}>
            <DisclaimerContentText>
              {t('*Relative yield advantage vs other tested products')}
            </DisclaimerContentText>
          </MainSectionContent>
        </>
      )}
    </SectionContainer>
  );
}
