import React, { useState } from 'react';
import { Dropdown, Radio } from 'syngenta-digital-cropwise-react-ui-kit';
import { StyledMenu, StyledMenuItem, StyledRadio } from './AbioticStressStyle';

interface Option {
  key: string;
  label: string;
}

interface AbioticDropdownProps {
  children?: JSX.Element;
  options: Option[];
  onChange: (selectedLabel: string) => void;
  selectedOption: string;
  isDisable?: boolean;
}

const AbioticDropdown: React.FC<AbioticDropdownProps> = ({
  children,
  options,
  onChange,
  selectedOption,
  isDisable,
}) => {
  const [showOptionsMenu, setShowOptionsMenu] = useState(false);

  const handleOptionSelect = (key: string) => {
    onChange(key);
    setShowOptionsMenu(false);
  };

  const onOpenChange = (flag: boolean) => {
    setShowOptionsMenu(flag);
  };

  return (
    <Dropdown
      open={showOptionsMenu}
      overlay={
        <StyledMenu data-testid="abiotic-dropdown-menu">
          <Radio.Group value={selectedOption}>
            {options.map(({ key, label }) => (
              <StyledMenuItem key={key} onClick={() => handleOptionSelect(key)}>
                <StyledRadio value={key}>{label}</StyledRadio>
              </StyledMenuItem>
            ))}
          </Radio.Group>
        </StyledMenu>
      }
      trigger={['click']}
      onOpenChange={onOpenChange}
      disabled={isDisable}
    >
      {children}
    </Dropdown>
  );
};

export default AbioticDropdown;
