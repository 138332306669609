import { useTranslation } from 'react-i18next';
import { colors } from 'syngenta-digital-cropwise-react-ui-kit';
import { useNavigate } from 'react-router-dom';
import { CSSProperties, useState, useEffect } from 'react';
import track from 'utils/amplitudeWrapper';
import {
  StyledModalView,
  StyledTitle,
  StyledParagraph,
  StyledButton,
  StyledIconsContainer,
} from './ConfirmationModal.styles';
import IconClose from 'components/Icons/IconClose';
import { EButtonType } from 'components/Buttons/ButtonBase';
import { useBreakpoint } from 'hooks';
import { useFlowActions } from 'context/actions/flowActions';
import { useAppDispatch } from 'context/AppState';
import { ModalAnimationDirections } from 'base/types/ModalBox';

interface IConfirmationModal {
  id?: string;
  title: string;
  body: string;
  bodyStyles?: CSSProperties;
  icon?: React.ReactNode;
  cancelButtonText?: string;
  confirmButtonText?: string;
  notificationColor?: string;
  buttonStyle?: string;
  onClickConfirm?: () => void;
  onClickCancel?: () => void;
  onClickOverlay?: () => void;
  isFieldViewInformation?: boolean;
  showPopUpforMobile?: boolean;
}
const ConfirmationModal = ({
  id = '',
  title,
  body,
  bodyStyles,
  cancelButtonText,
  confirmButtonText,
  notificationColor = colors.yellow40,
  onClickConfirm,
  onClickCancel,
  onClickOverlay,
  icon,
  isFieldViewInformation,
  buttonStyle,
  showPopUpforMobile = false,
}: IConfirmationModal): JSX.Element => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const { isMobile } = useBreakpoint();
  const { setShowPrompt } = useFlowActions();
  const [isEnglish, setIsEnglish] = useState(i18n.language === 'en');
  const [isPolish, setIsPolish] = useState(i18n.language === 'pl');
  const [slidingDirection, setSlidingDirection] = useState<ModalAnimationDirections | undefined>(
    isMobile ? ModalAnimationDirections.Up : undefined
  );
  const appDispatcher = useAppDispatch();
  const handleCancel = () => {
    if (isMobile) {
      setSlidingDirection(ModalAnimationDirections.Down);
    } else {
      onClickCancel?.();
    }
  };

  const handleOk = () => {
    if (onClickConfirm !== undefined) {
      onClickConfirm();
    } else {
      setShowPrompt({ show: false });
      appDispatcher({ type: 'reset-app' });
      setTimeout(() => {
        navigate('/');
      }, 0);
    }
  };

  const handleAnimationEnd = () => {
    if (!isMobile || slidingDirection === ModalAnimationDirections.Up) {
      return;
    }
    onClickCancel?.();
  };

  useEffect(() => {
    if (i18n.language === 'en') {
      setIsEnglish(true);
    } else {
      setIsEnglish(false);
    }
    if (i18n.language === 'pl') {
      setIsPolish(true);
    } else {
      setIsPolish(false);
    }
  }, [i18n.language]);

  useEffect(() => {
    if (id === 'ServiceUnavailableModal') {
      track('service unavailable popup displayed', { 'service unavailable': true });
    }
  }, [id]);

  return (
    <StyledModalView
      wrapperId={id}
      data-testid="go-back-home-modal"
      isClosable={false}
      width={360}
      isViewFooter={isMobile}
      isNotificationFooter={isMobile}
      notificationColor={notificationColor}
      onClickOverlay={onClickOverlay}
      slideDirection={slidingDirection}
      onAnimationEnd={handleAnimationEnd}
      showPopUpforMobile={showPopUpforMobile}
      content={
        <>
          {icon !== undefined && (
            <StyledIconsContainer>
              <div />
              <div data-testid="icon">{icon}</div>
              <div
                onClick={onClickOverlay}
                onKeyDown={() => {
                  //Placeholder comment to resolve sonarcloud comment
                }}
              >
                {<IconClose width={22} height={22} color={colors.neutral70} />}
              </div>
            </StyledIconsContainer>
          )}
          <StyledTitle>{title}</StyledTitle>
          <StyledParagraph style={bodyStyles}>{body}</StyledParagraph>
        </>
      }
      footer={
        <>
          {cancelButtonText && (
            <StyledButton
              isEnglish={isEnglish}
              isPolish={isPolish}
              data-testid="go-back-modal-cancel-button"
              type={buttonStyle === 'primary' ? EButtonType.primary : EButtonType.default}
              onClick={handleCancel}
              text={cancelButtonText}
            />
          )}
          {confirmButtonText && (
            <StyledButton
              isEnglish={isEnglish}
              isPolish={isPolish}
              buttonTestId="go-back-modal-save-button"
              data-testid="go-back-modal-save-button"
              type={isFieldViewInformation ? EButtonType.default : EButtonType.primary}
              onClick={handleOk}
              text={confirmButtonText}
            />
          )}
        </>
      }
    />
  );
};

export default ConfirmationModal;
