import { useTranslation } from 'react-i18next';

import { ContentContainerStyled, StyledModal } from './DeleteBoundaryNotificationModal.styles';
import ButtonBase, { EButtonType } from 'components/Buttons/ButtonBase';
import { ModalAnimationDirections } from 'base/types/ModalBox';
import { useState } from 'react';
import { useBreakpoint } from 'hooks';
import { getTranslatedFieldName } from 'utils/constants/Fields';

type TProps = {
  handleCancelClick: () => void;
  handleConfirmClick: () => void;
  isConfirmButtonDisabled: boolean;
  fieldName?: string
};

const DeleteNotificationModal = ({
  isConfirmButtonDisabled,
  handleConfirmClick,
  handleCancelClick,
  fieldName
}: TProps): JSX.Element => {
  const [t] = useTranslation();
  const { isMobile } = useBreakpoint();
  const [slidingDirection, setSlidingDirection] = useState<ModalAnimationDirections | undefined>(
    isMobile ? ModalAnimationDirections.Up : undefined
  );

  const handleOnCancelClick = () => {
    if (isMobile) {
      setSlidingDirection(ModalAnimationDirections.Down);
    } else {
      handleCancelClick();
    }
  };

  const handleOnConfirmClick = () => {
    handleConfirmClick();
  };

  const handleAnimationEnd = () => {
    if (!isMobile || slidingDirection === ModalAnimationDirections.Up) {
      return;
    }
    handleCancelClick();
  };

  const content = (
    <ContentContainerStyled>{t('Are you sure you want to delete it?')}</ContentContainerStyled>
  );

  const footer = (
    <>
      <ButtonBase onClick={handleOnCancelClick} text={t('Cancel')} />
      <ButtonBase
        onClick={handleOnConfirmClick}
        type={EButtonType.danger}
        isDisabled={isConfirmButtonDisabled}
        text={t('Delete')}
      />
    </>
  );

  return (
    <StyledModal
      displayBottomBorder={false}
      isViewFooter
      isNotificationFooter
      isClosable={false}
      className="delete-boundary-modal"
      title={`${t('Delete')} ${getTranslatedFieldName({
        defaultName: fieldName ?? '',
        t,
      })}`}
      content={content}
      footer={footer}
      forceViewFooterTitleInMobile
      slideDirection={slidingDirection}
      onAnimationEnd={handleAnimationEnd}
    />
  );
};

export default DeleteNotificationModal;
