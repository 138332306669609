/* eslint-disable no-confusing-arrow */
/* eslint-disable indent */
import styled from 'styled-components';
import breakpoints from 'base/constants/breakpoints';
import { colors } from 'syngenta-digital-cropwise-react-ui-kit';

interface MainSectionProps {
  isHeaderSection: boolean;
}

export const MainSectionContent = styled('div')<MainSectionProps>`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 14px 0px;

  @media screen and ${breakpoints.maxWidth.xl} {
    padding: ${({ isHeaderSection }) => (isHeaderSection ? '' : '0px 0px 80px 0px')};
  }
  @media screen and ${breakpoints.maxWidth.sm} {
    padding: ${({ isHeaderSection }) =>
      isHeaderSection ? '0px 0px 14px 16px' : '0px 0px 0px 16px'};
    margin-top: 16px;
    max-width: 95vw;
  }
`;

interface IsSectionContentProp {
  isRecommendationV2?: boolean;
}
export const SectionContent = styled.div<IsSectionContentProp>`
  display: flex;
  width: 100%;
  max-width: 1146px;
  flex-direction: column;

  @media screen and ${breakpoints.maxWidth.xl} {
    padding: 20px 50px;
  }
  @media screen and ${breakpoints.maxWidth.sm} {
    padding: 0px 0px 0px 0px;
    height: 650px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex: 1;
  }
`;

export const StyledText = styled('p')`
  font-weight: 400;
  font-size: 14px;
  color: ${colors.neutral80};
  font-style: italic !important;
`;
export const StyledTextInfo = styled('p')`
  font-size: 16px;
  width: 100%;
  @media screen and ${breakpoints.maxWidth.sm} {
    margin-top: 0;
    padding: 0 16px;
  }
  color: ${colors.neutral80};
  font-style: italic !important;
`;
