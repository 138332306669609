import styled, { css } from 'styled-components';
import mapBG from 'assets/images/map-skeleton.svg';
import breakpoints from 'base/constants/breakpoints';

export const SectionContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  position: relative;
  top: -10px;
`;

export const FullScreenChartContainer = styled.div<{ fullScreen?: boolean }>`
  position: ${({ fullScreen }) => (fullScreen ? 'fixed' : '')};
  height: ${({ fullScreen }) => (fullScreen ? '100%' : '')};
  width: ${({ fullScreen }) => (fullScreen ? '100%' : '')};
  padding: ${({ fullScreen }) => (fullScreen ? '0 20px 20px' : '')};
  z-index: ${({ fullScreen }) => (fullScreen ? '9999' : 'unset')};
  width: 100%;

  background-color: white;
  top: 0;
  left: 0;
  right: 0;
  overflow: auto;
`;

export const MapSkeleton = styled.div`
  background-image: url(${mapBG});
  background-size: cover;
  margin-top: -30px;
  flex: 1;
  width: 100%;
`;

interface IsMobileProps {
  isMobile: boolean;
}

export const MapContainer = styled(FullScreenChartContainer)<IsMobileProps>`
  display: flex;
  flex: 1;
  width: 100%;
  min-height: 640px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: ${({ fullScreen }) => (fullScreen ? '0 !important' : '')};

  & .mapboxgl-ctrl-top-right .mapboxgl-ctrl {
    margin: 8px 60px 0 0;
  }

  @media screen and (orientation: landscape) {
    min-height: ${({ isMobile }) => (isMobile ? '100%' : '640px')} !important;
  }

  @media screen and (${breakpoints.maxWidth.sm}) {
    .mapboxgl-ctrl button:not(:disabled):hover {
      background-color: #232630;
    }
    .mapboxgl-ctrl-top-right {
      width: 1% !important;
    }
    .syt-antd-btn:hover {
      background-color: #232630;
    }
    .mapboxgl-ctrl-attrib.mapboxgl-compact {
      top: 8px !important;
    }
  }

  .mapboxgl-popup-content {
    left: 124px !important;
    border-radius: 8px;
  }
`;

export const StyledUserMarker = styled.img.attrs((props) => ({
  src: props.src,
}))`
  background-size: cover;
  height: 50px;
  width: 42px;
  cursor: pointer;
`;

export const FarmPin = styled.div`
  img {
    width: 32px;
    height: 36px;
  }
`;

export const FarmSelect = styled.div`
  width: 300px;
  height: 48px;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.8);
  margin-left: 61px;
`;

const sharedStyles = css`
  border-radius: 8px;
  background-color: rgba(35, 38, 48, 0.75);
  display: flex;
  justify-content: left;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const ProductFieldInfoCard = styled.div`
  position: absolute;
  bottom: 33.5%;
  padding: 8px;
  width: 300px;
  margin: 10px 65px;
  max-height: 190px;
  ${sharedStyles}
`;

export const SoilInfoCard = styled.div`
  position: absolute;
  right: 0;
  bottom: 30%;
  padding: 8px 8px 0 8px;
  margin-right: 65px;
  width: 283px;
  max-height: 84px;
  ${sharedStyles}
`;
