import { colors, Layout, Button, SYT_PREFIX } from 'syngenta-digital-cropwise-react-ui-kit';
import styled from 'styled-components';
import LogoCropwise from 'components/Icons/IconLogoCropwise';
import breakpoints from 'base/constants/breakpoints';
import isMobileTool from 'ismobilejs';

const isMobile = isMobileTool(window.navigator).phone;

const { Header: AntHeader } = Layout;

export const StyledHeader = styled(AntHeader)`
  padding: ${isMobile ? '16px' : '16px 64px'};
  height: 76px;
  position: sticky;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.neutral00};
  border-bottom: 2px solid ${colors.neutral20};
  z-index: 2000;
`;

export const StyledButtonHelp = styled(Button)`
  border-radius: 4px;
  border: 1px solid var(--Neutral-neutral-30, #c2c7d0);
  background: var(--Neutral-neutral-00, #fff);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 52px;
  height: 44px;

  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const StyledButtonLanguage = styled(Button)`
  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  width: 70px;
  height: 44px;

  /* Neutral / neutral-10

  F3F4F6
  */
  background: ${colors.neutral10};
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  flex-grow: 0;
`;

export const MobileExtendedHeader = styled.div`
  position: sticky;
  top: 76px;
  z-index: 999;
  background-color: #14151cee;
`;

export const StyledDivider = styled.div({
  paddingRight: '16px',
});

export const IconLogoCrop = styled(LogoCropwise)({
  cursor: 'pointer',
});

export const StyledDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledLanguageItems = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const StyledLanguageText = styled.div`
  color: ${colors.neutral100};
  margin-left: 10px;
`;

export const StyledLanguageDropdownDivider = styled.div`
  padding-right: 3px;
`;
interface StickyBarProps {
  isLoading?: boolean;
}
export const StickyBar = styled.div<StickyBarProps>`
  position: sticky;
  top: 0;
  display: flex;
  justify-content: flex-end;
  padding: 24px 8px;
  gap: 24px;
  z-index: 99;

  @media screen and ${breakpoints.maxWidth.sm} {
    padding: ${({ isLoading }) => isLoading && '24px 16px'};
    flex-direction: ${({ isLoading }) => (isLoading ? 'column' : 'row')};
    gap: 16px;
  }

  button,
  a {
    display: flex;
    flex: 1;
    max-width: 216px;

    @media screen and ${breakpoints.maxWidth.sm} {
      flex: unset;
      max-width: unset;
    }
  }

  .${SYT_PREFIX}-skeleton {
    display: flex;
    flex: 1;
    max-width: 216px;

    span {
      flex: 1;
    }
  }
`;

export const LangSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  .kebabMenuButtonClass {
    background-color: transparent;
    border: transparent;
  }
  .shareButtonClass {
    background-color: transparent;
    border: transparent;
    cursor: pointer;
  }
`;
