import styled from 'styled-components';
import { Button, colors, SYT_PREFIX } from 'syngenta-digital-cropwise-react-ui-kit';
import isMobileTool from 'ismobilejs';
import breakpoints from 'base/constants/breakpoints';

interface ImageForCrop {
  cropName: string;
}

const isMobile = isMobileTool(window.navigator).phone;

interface BackToTopBtnProps {
  visible?: boolean;
}

export const BackToTopBtn = styled(Button)<BackToTopBtnProps>`
  border-radius: 50%;
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 40px;
  height: 40px;
  background-color: ${colors.neutral60};
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  z-index: 55;

  &:hover,
  &:active,
  &:focus {
    background-color: ${colors.neutral60};
  }
`;

export const Root = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: ${isMobile ? 'hidden' : 'auto'};

  .${SYT_PREFIX}-skeleton {
    &-content {
      display: flex;
      flex-direction: column;
    }

    &-button,
    &-title,
    &-paragraph li {
      background-color: ${colors.neutral20} !important;
      display: inline-block;
    }

    &-title {
      height: 40px;
    }

    &-paragraph li {
      height: 29px;
    }

    &-paragraph {
      & li + li {
        margin-top: 11px;
      }
    }

    &-title {
      margin: auto;
      & + .${SYT_PREFIX}-skeleton-paragraph {
        margin-top: 16px;
      }
    }
  }
`;

export const FeedbackBtn = styled.button`
  position: fixed;
  right: 20px;
  bottom: 80px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 55;
  background: #000;
  border: none;
  outline: none;
  max-height: 32px;
  border-radius: 50%;
  padding: 20px 10px;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    background: #000;
  }
`;
export const LandscapeWarningMessageContainer = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 142px 100px;
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
  text-align: center;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 999;
`;

export const FeedbackImg = styled.img`
  height: 20px;
  width: 20px;
  filter: invert(1);
`;

export const ContentContainer = styled.div<ImageForCrop>`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #f8fafe;
`;

export const DiagnosticTab = styled.div`
  padding: ${isMobile ? '16px' : '4px 64px'};
  background-color: #fff;

  p.heading {
    font-weight: 600;
    font-size: 28px;
    line-height: 44px;
    margin-top: 12px;
    @media screen and ${breakpoints.maxWidth.md} {
      margin-top: 18px;
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      letter-spacing: -0.0075em;
    }
  }
`;

export const RecommendationTab = styled.div``;
export const PrimaryContainer = styled.div`
  padding: 16px 64px;
  background-color: #f8fafe;
  @media screen and ${breakpoints.maxWidth.sm} {
    padding: 0px;
  }
`;
export const SecondaryContainer = styled.div`
  padding: 16px 64px;
  background-color: #ffffff;
  border-top: 2px solid ${colors.neutral20};
  @media screen and ${breakpoints.maxWidth.sm} {
    padding: 0px;
    border-top: 10px solid ${colors.neutral10};
  }
`;
